import React, {FC, useCallback, useState} from "react";
import {Input} from "../components/form/Input";
import {ModalFooter} from "../components/layout/ModalProvider";
import {useRefresh} from "../components/RefreshController";
import {ErrorBag, useApiCall, ValidationError} from "../api/api";
import {Ticket} from "../api/dto";
import {InputErrors} from "../components/form/InputErrors";

export const EditTicketNameModal: FC<{ticket: Ticket}> = (props) => {
  const [name, setName] = useState(props.ticket.subject ?? "")
  const [errors, setErrors] = useState<ErrorBag>({})

  const {editTicket} = useApiCall()
  const reload = useRefresh()

  const save = useCallback( async () => {
    await editTicket(
      props.ticket.id,
      {subject: name}
    ).catch(error => {
      if (error instanceof ValidationError) {
        setErrors(error.errors)
        throw new Error('Validation error')
      }
      throw error
    })
    reload()
  }, [name])

  return <>
    <form onSubmit={() => save()} className={"flex-1"}>
      <div className={"items-center"}>
        <Input label={"Onderwerp"} type={'text'} value={name} onChange={setName} />
        <InputErrors errors={errors} field={'note'} />
      </div>
    </form>
    <ModalFooter text={"Opslaan"} onSubmit={save}/>
  </>
}