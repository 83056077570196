import React, {FC, useEffect, useState} from "react";
import {useApi} from "../../api/APIContext";
import {usePersistentState} from "../../util/usePersistentState";
import {usePermission} from "../../permissions/PermissionContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faArrowDownAZ,
  faChevronDown,
  faChevronUp,
  faFilter,
  faSearch, faTimes,
  faTimesCircle
} from "@fortawesome/free-solid-svg-icons";
import {Asset, AssetType, Customer, TicketStatus} from "../../api/dto";
import {Button} from "../form/Button";
import {v4} from "uuid";
import {Select} from "../form/Select";
import {TicketPriorityLabel, TicketStatusLabel} from "../tickets/TicketListCard";
import {Autocomplete} from "../form/Autocomplete";
import {faCheckCircle} from "@fortawesome/free-regular-svg-icons";

const sortOptions = {
  createdDesc: 'Nieuwste assets',
  createdAsc: 'Oudste assets'
}

type FilterMode = 'and'|'or'
interface Filter {
  id: string
  field: keyof Pick<Asset, 'asset_nr'|'name'|'brand'|'user_id'|'customer_id'|'model'|'serial_number'|'asset_type_id'>
  values: string[]
  invert: boolean
  enable: boolean
}

export const SearchControlBar: FC<{ assets: Asset[], assetTypes: AssetType[], customers: Customer[], tenantSlug: string, onSearchedAssetsChange: (assets: Asset[]) => void }> = (props) => {
  const {currentTenant} = useApi()
  const [search, setSearch] = usePersistentState<string>('asset-overview-search', '')
  const [sort, setSort] = usePersistentState<keyof typeof sortOptions>(`asset-overview-sort-${props.tenantSlug}`, "createdDesc")
  const [filterConfiguratorExpanded, setFilterConfiguratorExpanded] = useState<boolean>(false)
  const [filterMode, setFilterMode] = usePersistentState<FilterMode>(`assets-overview-filter-mode-${props.tenantSlug}`, 'and')
  // @ts-ignore Die filter objecten kunnen prima geserialized worden.
  const [filters, setFilters] = usePersistentState<Filter[]>(`asset-overview-filters-${props.tenantSlug}`, createDefaultFilters(props.assets))

  useEffect(() => {
    const activeFilters = filters.filter(f => f.enable)
    const filteredAssets = searchFilterSortAssets(props.assets, props.assetTypes, search, activeFilters, sort, filterMode)
    props.onSearchedAssetsChange(filteredAssets)
  }, [props.assets, search, sort, filters, filterMode])

  return <div className={"mb-8 bg-white dark:bg-zinc-700 border border-slate-200 dark:border-zinc-500 rounded"}>
    <div className={"flex flex-col xl:flex-row items-stretch"}>
      {/* Search */}
      <label
        className={`-ml-[1px] -my-[1px] flex-1 border ${usePermission().canAccessCustomer() ? 'xl:rounded-r-none border-transparent rounded' : 'xl:border-r border-slate-200 dark:border-zinc-500 rounded-l'}  flex items-center group focus-within:border-brand-600 focus-within:text-brand-800 focus-within:dark:text-white px-4 py-3`}>
        <FontAwesomeIcon icon={faSearch} className={"mr-3 text-slate-600 dark:text-zinc-300"}/>
        <input
          type={'text'}
          placeholder={'Zoek op asset nummer, type, etc,'}
          className={"rounded h-8 flex-1 outline-none dark:bg-zinc-700"}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        {search.length > 0 && <button className={"h-8 w-8 flex items-center justify-center text-slate-400 dark:text-zinc-300 hover:text-brand-600 hover:dark:text-brand-500"} onClick={() => setSearch('')}>
          <FontAwesomeIcon icon={faTimesCircle} />
        </button>}
      </label>
      {/* Filter */}
      {usePermission().canAccessCustomer() &&
        <div className={`flex-1 flex items-center px-4 py-3 xl:border-r xl:border-l border-slate-200 dark:border-zinc-500`}>
          <FontAwesomeIcon icon={faFilter} className={'mr-3 text-slate-600 dark:text-zinc-300'} />
          <span className={"flex-1"}>
            <span className={`rounded-full ${filters.filter(x => x.enable).length > 0 ? 'bg-brand-100 dark:bg-brand-300 text-brand-800 dark:text-brand-700' : 'bg-slate-100 text-slate-800'} px-2 mr-1 text-sm font-medium`}>{filters.filter(x => x.enable).length}
            </span> filters actief</span>
          <Button type={'secondary'} size={'sm'} text={'Filters aanpassen'} icon={filterConfiguratorExpanded ? faChevronUp : faChevronDown} onClick={() => setFilterConfiguratorExpanded(x => !x)}/>
        </div>}

      {/* Sort */}
      <div
        className={"-mr-[1px] -my-[1px] flex-1 border rounded xl:rounded-l-none border-transparent flex items-center group focus-within:border-brand-600 focus-within:text-brand-800 focus-within:dark:text-white px-4 py-3"}>
        <FontAwesomeIcon icon={faArrowDownAZ} className={"mr-3 text-slate-600 dark:text-zinc-300"}/>
        <select value={sort} onChange={(e) => setSort(e.target.value as keyof typeof sortOptions)}
                className={"rounded h-8 flex-1 outline-none dark:bg-zinc-700"}>{Object.entries(sortOptions).map(([key, value]) =>
          <option key={key} value={key}>{value}</option>)}</select>
      </div>
    </div>
    {filterConfiguratorExpanded && <div className={"px-4 py-3 border-t border-slate-200 dark:border-zinc-500"}>

      {/* Customize*/}
      <h3 className={"text-lg font-semibold mt-4 mb-2"}>Filters aanpassen</h3>
      <div className={"h-6 mb-3"}>
        <span className={"text-sm mr-2"}>Modus:</span>
        <button
          className={`text-sm font-medium border ${filterMode === 'and' ? 'border-brand-300 dark:border-brand-700 bg-brand-100 dark:bg-brand-700 text-brand-900 dark:text-brand-200' : 'border-slate-300 dark:border-zinc-500 bg-slate-100 dark:bg-zinc-600 hover:bg-slate-200 hover:dark:bg-zinc-700 text-slate-600 dark:text-zinc-300'} rounded-l px-1`}
          onClick={() => setFilterMode('and')}>AND
        </button>
        <button
          className={`text-sm font-medium border ${filterMode === 'or' ? 'border-brand-300 dark:border-brand-700 bg-brand-100 dark:bg-brand-700 text-brand-900 dark:text-brand-200' : 'border-slate-300 dark:border-zinc-500 bg-slate-100 dark:bg-zinc-600 hover:bg-slate-200 hover:dark:bg-zinc-700 text-slate-600 dark:text-zinc-300'} rounded-r px-1`}
          onClick={() => setFilterMode('or')}>OR
        </button>
      </div>

      <div className={"flex flex-col space-y-2"}>
        {filters.map((filter) => {
          return <ConfigureFilter key={filter.id} filter={filter}
                                  onDelete={() => setFilters(old => old.filter(f => f.id !== filter.id))}
                                  onUpdate={(updatedFilter) => setFilters(old => old.map(f => f.id !== filter.id ? f : updatedFilter))}
                                  assetTypes={props.assetTypes} customers={props.customers} />
        })}
        <div className={"flex space-x-4"}>
          <Button type={"secondary"} size={'sm'} text={'Filter toevoegen'} icon={faFilter}
                  onClick={() => setFilters([...filters, {
                    id: v4(),
                    field: 'customer_id',
                    values: [],
                    invert: false,
                    enable: true
                  }])}/>
          <Button type={"danger"} size={'sm'} text={'Reset naar standaard'} icon={faTimes}
                  onClick={() => setFilters(createDefaultFilters(props.assets))}/>
        </div>
      </div>


    </div>
    }
  </div>
}

function createDefaultFilters(assets: Asset[]): Filter[] {
  return [
    // {id: v4(), field: 'asset_nr', values: [''], invert: false, enable: true}
  ]
}

const ConfigureFilter: FC<{filter: Filter, onDelete: () => void, onUpdate: (filter: Filter) => void, assetTypes: AssetType[], customers: Customer[]}> = (props) => {
  return <div className={"border border-slate-200 dark:border-zinc-500 rounded px-2 py-2 flex"}>
    <label className={"h-10 w-10 mr-2 rounded hover:bg-brand-200 hover:dark:bg-zinc-600 cursor-pointer flex items-center justify-center"}>
      <input type="checkbox" className={"accent-brand-700"} checked={props.filter.enable}
             onChange={(e) => props.onUpdate({...props.filter, enable: e.target.checked})}/>
    </label>
    <div className={"-mt-1 mr-2"}>
      <Select label={''} options={{'asset_type_id': 'Type', 'customer_id': 'Klant',}}
              value={props.filter.field}
              onChange={(newField) => props.onUpdate({...props.filter, field: newField as Filter['field'], values: []})}/>
    </div>
    <div className={"-mt-1 mr-2"}>
      <Select label={''} options={{'=': 'is', '!=': 'is niet'}}
              value={props.filter.invert ? '!=' : '='}
              onChange={(newInvert) => props.onUpdate({...props.filter, invert: newInvert === '!='})}/>
    </div>
    <div className={'flex-1'}>
      {props.filter.field === 'asset_type_id' && <div className={"flex flex-wrap -mb-2"}>
        {props.assetTypes.map((asset, i) => {
          return <label key={i} className={`mr-2 mb-2 h-10 pl-2 rounded-lg flex items-center border ${props.filter.values.includes(asset.id) ? 'border-slate-600 bg-slate-100 dark:border-zinc-400 dark:bg-zinc-700' : 'border-slate-200 dark:border-zinc-600'}`}>
            <input type="checkbox" className={"mr-2 accent-brand-700"} checked={props.filter.values.includes(asset.id)}
                   onChange={(e) => props.onUpdate({...props.filter, values: e.target.checked ? [...props.filter.values, asset.id] : props.filter.values.filter(v => v !== asset.id)})}/>
            <AssetTypeLabel assetType={asset}/>
          </label>
        })}
      </div>}
      {props.filter.field === 'customer_id' && <div className={"-mt-1 flex"}>
        <div>
          <Autocomplete label={''} options={{'-': 'Geen', ...Object.fromEntries(props.customers.map(customer => [customer.id, customer.name]))}}
                        value={props.filter.values[0] ?? '-'}
                        onChange={(newCustomer) => props.onUpdate( {...props.filter, values: [newCustomer]})}/>
        </div>
      </div>}
    </div>
    <Button type={'danger'} size={'md'} text={'Verwijderen'} icon={faTimes} onClick={props.onDelete}/>
  </div>
}

function searchFilterSortAssets(assets: Asset[], assetTypes: AssetType[], search: string, filters: Filter[], sort: keyof typeof sortOptions, filterMode: FilterMode): Asset[] {
  // 1. Filter
  const filteredAssets = assets.filter(asset => {
    const matches = filters.filter(filter => {
      if (filter.field === 'asset_nr') {
        return filter.values.includes(asset.asset_nr) !== filter.invert
      }
      if (filter.field === 'name') {
        return filter.values.includes(asset.name) !== filter.invert
      }
      if (filter.field === 'model') {
        if (asset.model !== null) {
          return filter.values.includes(asset.model) !== filter.invert
        }
      }
      if (filter.field === 'serial_number') {
        if (asset.serial_number !== null) {
          return filter.values.includes(asset.serial_number) !== filter.invert
        }
      }
      if (filter.field === 'user_id') {
        if (asset.user_id !== null) {
          return filter.values.includes(asset.user_id) !== filter.invert
        }
      }
      if (filter.field === 'brand') {
        if (asset.brand !== null) {
          return filter.values.includes(asset.brand) !== filter.invert
        }
      }
      if (filter.field === 'customer_id') {
        return filter.values.includes(asset.customer_id) !== filter.invert
      }
      if (filter.field === 'asset_type_id') {
        return filter.values.includes(asset.asset_type_id) !== filter.invert
      }
      return false
    })
    // If filtermode is AND, all filters must match
    // If filtermode is OR, at least one filter must match
    return filterMode === 'and' ? matches.length === filters.length : matches.length > 0
  })

  // 2. Search
  const assetTypeMap = Object.fromEntries(assetTypes.map(a => [a.id, a]))
  const searchedAssets = assets.filter(asset => {
    const filterableFields = [
      asset.asset_nr,
      asset.model,
      asset.brand,
      asset.serial_number,
      asset.name,
      assetTypeMap[asset.asset_type_id ?? '-']?.type,
    ]
    if (search.trim() === '') {
      return false
    }
    // There must be at least one match on one field
    return filterableFields.some(field => field?.toLowerCase().includes(search.trim().toLowerCase()))
  })
  const filteredSearchedAssets = filteredAssets.filter(asset => {
    const filterableFields = [
      asset.asset_nr,
      asset.model,
      asset.brand,
      asset.serial_number,
      asset.name,
      assetTypeMap[asset.asset_type_id ?? '-']?.type,
    ]
    if (search.trim() === '') {
      return true
    }
    // THere must be at least one match on one field
    return filterableFields.some(field => field?.toLowerCase().includes(search.trim().toLowerCase()))
  })
  const searchedAndFilteredAssetsMap: {[key: Asset['id']]: Asset} = {}
  searchedAssets.forEach(a => searchedAndFilteredAssetsMap[a.id] = a)
  filteredSearchedAssets.forEach(a => searchedAndFilteredAssetsMap[a.id] = a)
  const searchedAndFilteredAssets = Object.values(searchedAndFilteredAssetsMap)

  // 3. Sort
  return searchedAndFilteredAssets.sort((a, b) => {
    if (sort === 'createdAsc') {
      return a.created_at > b.created_at ? 1 : -1
    }

    if (sort === 'createdDesc') {
      return a.created_at < b.created_at ? 1 : -1
    }
    return 0;
  })
}

export const AssetTypeLabel: FC<{assetType: AssetType}> = (props) => {
  return <span className={`text-xs font-medium px-2 py-1 mr-2 rounded border`}><FontAwesomeIcon icon={faCheckCircle} className={"mr-1"} />{props.assetType.type}</span>
}