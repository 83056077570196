import {createContext, FC, ReactNode, useContext} from "react";
import {usePersistentState} from "../util/usePersistentState";
import {useTenant} from "../tenant/TenantContext";

interface BetaContextType {
  enrolledInTimeBeta: boolean
  setEnrolledInTimeBeta: (enrolled: boolean) => void
  weeklyBillableTarget: number|null
  setWeeklyBillableTarget: (target: number|null) => void
  assetDetailsSidebarBeta: boolean
  setAssetDetailsSidebarBeta: (enabled: boolean) => void
}
const LocalSettingsContext = createContext({} as BetaContextType);

export const LocalSettingsContextProvider: FC<{children: ReactNode}> = (props) => {
  const tenant = window.location.pathname.split('/')[1]
  const [enrolledInTimeBeta, setEnrolledInTimeBeta] = usePersistentState<boolean>("enrolledInTimeBeta", false)
  const [weeklyBillableTarget, setWeeklyBillableTarget] = usePersistentState<number|null>(`weeklyBillableTarget-${tenant}`, null)
  const [assetDetailsSidebarBeta, setAssetDetailsSidebarBeta]  = usePersistentState<boolean>("assetDetailsSidebarBeta", false)
  return <LocalSettingsContext.Provider value={{
    enrolledInTimeBeta,
    setEnrolledInTimeBeta,
    weeklyBillableTarget,
    setWeeklyBillableTarget,
    assetDetailsSidebarBeta,
    setAssetDetailsSidebarBeta
  }}>{props.children}</LocalSettingsContext.Provider>
}

export const useLocalSettings = () => {
  return useContext(LocalSettingsContext)
}