import React, {FC} from "react";
import {Customer, Ticket, User} from "../../../api/dto";

import {useTenant} from "../../../tenant/TenantContext";
import {useApiCall} from "../../../api/api";

export const TicketSubscribersTab: FC<{ ticket: Ticket, customer?: Customer, users: User[]}> = (props) => {
  const {tenant} = useTenant()
  const {} = useApiCall()
  const userMap: {[id: string]: User} = Object.fromEntries([...props.users.map(u => [u.id, u]), ...(props.customer?.users??[]).map(u => [u.id, u])])
  return <div className={"rounded border border-slate-300 dark:border-zinc-500 p-2 bg-white dark:bg-zinc-700 flex-col"}>
    {props.ticket.subscribers.map((sub,index) => {
      let subRowUser = userMap[sub.id]
      let isCustomer = props.customer?.id === subRowUser.customer_id
      return <div className={'flex items-center px-2'}>
        <div
          className={"flex items-center justify-center h-10 w-10 text-sm font-medium text-brand-600 dark:text-brand-200 bg-brand-100 dark:bg-brand-700 border border-brand-100 dark:border-brand-800 rounded my-2"}>
          {subRowUser.name.slice(0, 2).toUpperCase()}
        </div>
        <div className={'flex flex-col ml-3'}>
          <h2 className={``}>{subRowUser.name}</h2>
          <span className={'text-sm font-normal text-slate-700 dark:text-zinc-300'}>{isCustomer ? props.customer?.name : "Intern"} &middot; {subRowUser.email}</span>
        </div>
      </div>
    })}
  </div>
}
