import React, {FC, useEffect, useState} from "react";
import {ContentContainer} from "../components/content/ContentContainer";
import {PageIconHeader} from "../components/layout/PageIconHeader";
import {Breadcrumbs} from "../components/content/Breadcrumbs";
import {PageHeader} from "../components/content/PageHeader";
import {
  faAdd, faArrowDownAZ, faChevronDown, faChevronUp, faCookieBite,
  faFilter,
  faInbox, faSearch, faSliders, faTicket, faTimes, faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import {useModal} from "../components/layout/ModalProvider";
import {Button} from "../components/form/Button";
import {AddTicketModal} from "../modals/AddTicketModal";
import {useApiCall} from "../api/api";
import {Customer, Team, Ticket, TicketPriority, TicketStatus, User} from "../api/dto";
import {useApi, useFetchedResource} from "../api/APIContext";
import {useRefreshEffect} from "../components/RefreshController";
import {SimplePaginate} from "../components/data/SimplePaginate";
import {TicketListCard} from "../components/tickets/TicketListCard";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {SearchControlBar} from "../components/tickets/SearchControlBar";
import {useTenant} from "../tenant/TenantContext";
import {usePermission} from "../permissions/PermissionContext";
import {v4} from "uuid";

export const Tickets: FC = () => {
  const {getAllTicketsForTenant, getAllPriorities, getAllStatuses, getCustomers, getAllUsersForTenant, getTeams, getTicketTemplates} = useApiCall()
  const {resource: tickets, loading: loadTickets, reload: reloadTickets} = useFetchedResource(() => getAllTicketsForTenant())
  const {resource: ticketPriorities, loading: loadingPriorities, reload: reloadPriorities} = useFetchedResource(() => getAllPriorities())
  const {resource: ticketTemplates, loading: loadingTemplates, reload: reloadTemplates} = useFetchedResource(() => getTicketTemplates())
  const {resource: ticketStatuses, reload: reloadStatuses} = useFetchedResource(() => getAllStatuses())
  const {resource: customers, loading: loadingCustomers, reload: reloadCustomers} = useFetchedResource(() => getCustomers())
  const {resource: users, reload: reloadUsers} = useFetchedResource(() => getAllUsersForTenant())
  const {resource: teams, reload: reloadTeams} = useFetchedResource(() => getTeams())
  const {tenant} = useTenant()
  const permissions= usePermission()

  useRefreshEffect(() => {
    reloadTickets(undefined)
    reloadPriorities(undefined)
    reloadStatuses(undefined)
    reloadCustomers(undefined)
    reloadUsers(undefined)
    reloadTeams(undefined)
    reloadTemplates(undefined)
  })
  const {open: openCreateModal} = useModal({title: 'Ticket toevoegen', body: <AddTicketModal ticketTemplates={ticketTemplates ?? []} priorities={ticketPriorities ?? []} customers={customers ?? []} users={users ?? []} />, size: 'lg'})
  return (
    <ContentContainer size={'xl'}>
      <div className={"flex justify-between items-end"}>
        <PageIconHeader icon={faInbox}>
          <Breadcrumbs crumbs={[
            {label: "Servicedesk", href: `/${tenant}/ticket`},
          ]} currentPage={"Tickets"} />
          <PageHeader>Tickets</PageHeader>
        </PageIconHeader>
        {usePermission().canAddTicket() &&
          <div className={"flex space-x-4 mb-8"}>
            <Button size={"md"} type={"primary"} text={"Ticket toevoegen"} icon={faAdd}
                    disabled={loadingPriorities || loadingTemplates || loadingCustomers}
                    onClick={() => openCreateModal()}/>
          </div>
        }
      </div>

      {loadTickets ? <>
        Loading tickets...
      </> : <>
        {ticketStatuses && ticketPriorities && customers && users && <>
          {permissions.canAccessTeam() ? <>
            <TicketList tickets={tickets ?? []} reload={() => reloadTickets(undefined, true)} statuses={ticketStatuses} users={users} teams={teams ? teams : []} priorities={ticketPriorities} customers={customers} customer={customers}/>
          </> : <>
            <MinimalTicketList tickets={tickets ?? []} statuses={ticketStatuses} users={users} priorities={ticketPriorities} customers={customers} customer={customers}/>
          </>}
        </>}
      </>}

    </ContentContainer>
  );
}

const TicketList: FC<{
  tickets: Ticket[],
  reload: () => void,
  statuses: TicketStatus[],
  priorities: TicketPriority[],
  customers: Customer[],
  users: User[],
  teams: Team[],
  customer: Customer[]
}> = props => {
  const {tenant} = useTenant()
  const [searchedTickets, setSearchedTickets] = useState<Ticket[]>([])
  // Calculate the amount of open tickets, so it can be displayed in the placeholder when there are no search results.
  const openStatuses = props.statuses.filter(status => !status.isClosed).map(s => s.id)
  const openTickets = props.tickets.filter(ticket => openStatuses.includes(ticket.status_id)).length
  return <div>
    {tenant && <SearchControlBar
      tickets={props.tickets}
      statuses={props.statuses}
      priorities={props.priorities}
      customers={props.customers}
      users={props.users}
      teams={props.teams ?? []}
      tenantSlug={tenant}
      onSearchedTicketsChange={(tickets) => setSearchedTickets(tickets)}
    />}
    {searchedTickets.length === 0 && <div className={"border border-slate-200 dark:border-zinc-500 rounded h-64 flex flex-col items-center justify-center text-slate-600 dark:text-zinc-300 text-lg font-medium"}>
      <FontAwesomeIcon icon={openTickets > 0 ? faFilter : faCookieBite} className={'mb-8 text-4xl'} />
      {openTickets > 0 ? <>
        <h2>Er zijn nog {openTickets} open tickets die niet door je zoekopdracht of filter heen komen.</h2>
        <p className={"font-normal text-regular mt-4"}>Verbreed het filter/zoekopdracht om meer tickets te zien</p>
      </> : <>
        <h2>Alle tickets zijn opgelost!</h2>
        <p className={"font-normal text-regular mt-4"}>Geweldig!</p>
      </>}
    </div>}
    <SimplePaginate paginationLimit={20} data={searchedTickets} resultsBuilder={(paginatedTickets) => {
      return <>{paginatedTickets.map((ticket, i) => <TicketListCard
        key={i}
        ticket={ticket}
        isFirst={i===0}
        isLast={i===(paginatedTickets.length - 1)}
        team={props.teams.find(team => team.id === ticket.team_id) ?? null}
        customer={props.customers.find(customer => customer.id === ticket.customer_id) ?? null}
        status={props.statuses.find(status => status.id === ticket.status_id) ?? null}
        priority={props.priorities.find(priority => priority.id === ticket.priority_id) ?? null}
        assignee={props.users.find(user => user.id === ticket.assignee_id) ?? null}
      />)}</>
    }}/>
  </div>
}

const MinimalTicketList: FC<{
  tickets: Ticket[],
  statuses: TicketStatus[],
  priorities: TicketPriority[],
  customers: Customer[],
  users: User[],
  customer: Customer[]
}> = props => {
  const {tenant } = useTenant()
  const [search, setSearch] = useState('')
  const openStatuses = props.statuses.filter(status => !status.isClosed).map(s => s.id)
  const searchedTickets = props.tickets.filter(ticket => {
    return ticket.subject?.toLowerCase().includes(search.toLowerCase()) || ticket.description?.toLowerCase().includes(search.toLowerCase()) || ticket.number?.toLowerCase().includes(search.toLowerCase())
  })

  return <div>
    <div className={"mb-8 bg-white dark:bg-zinc-700 border border-slate-200 dark:border-zinc-500 rounded"}>
      <div className={"flex flex-col xl:flex-row items-stretch"}>
        {/* Search */}
        <label
          className={`-ml-[1px] -my-[1px] flex-1 border xl:border-r border-slate-200 dark:border-zinc-500 rounded flex items-center group focus-within:border-brand-600 focus-within:text-brand-800 focus-within:dark:text-white px-4 py-3`}>
          <FontAwesomeIcon icon={faSearch} className={"mr-3 text-slate-600 dark:text-zinc-300"}/>
          <input
            type="text"
            placeholder={'Zoek op ticket nummer, etc.'}
            className={"rounded h-8 flex-1 outline-none dark:bg-zinc-700"}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          {search.length > 0 && <button
            className={"h-8 w-8 flex items-center justify-center text-slate-400 dark:text-zinc-300 hover:text-brand-600 hover:dark:text-brand-500"}
            onClick={() => setSearch('')}>
            <FontAwesomeIcon icon={faTimesCircle}/>
          </button>}
        </label>
      </div>
    </div>
    <SimplePaginate<Ticket> paginationLimit={20} data={searchedTickets} resultsBuilder={(paginatedTickets) => {
      const sortedPaginatedTickets = paginatedTickets.sort((a, b) => {
        const aClosed = openStatuses.includes(a.status?.id ?? '')
        const bClosed = openStatuses.includes(b.status?.id ?? '')
        if (aClosed && !bClosed) return -1
        if (!aClosed && bClosed) return 1
        return a.createdAt > b.createdAt ? -1 : 1;
      })
      return <>{sortedPaginatedTickets.map((ticket, i) => <React.Fragment key={i}><TicketListCard
        ticket={ticket}
        isFirst={i === 0}
        isLast={i === (paginatedTickets.length - 1)}
        team={null}
        limited={true}
        customer={props.customers.find(customer => customer.id === ticket.customer_id) ?? null}
        status={props.statuses.find(status => status.id === ticket.status_id) ?? null}
        priority={props.priorities.find(priority => priority.id === ticket.priority_id) ?? null}
        assignee={props.users.find(user => user.id === ticket.assignee_id) ?? null}
      /></React.Fragment>)}</>
    }}/>
  </div>
}
