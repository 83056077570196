import {FC, useCallback, useEffect, useState} from "react";
import {Asset, AssetType, Customer, User} from "../../api/dto";
import {useLocalSettings} from "../LocalSettingsContext";
import {useApi} from "../../api/APIContext";
import {IconDropdownButton} from "../form/IconButtonDropdown";
import {useRefresh} from "../RefreshController";
import {useApiCall} from "../../api/api";

export const AssetButtons: FC<{ asset: Asset, allCustomers: Customer[], assetTypes: AssetType[], users: User[]}> = (props) => {
  const {assetDetailsSidebarBeta} = useLocalSettings()
  const {currentTenant} = useApi()
  const {editAsset} = useApiCall()
  const refactoredCustomers = props.allCustomers.map((item) => [item.id, item.name])
  const refactoredAssetTypes = props.assetTypes.map((item) => [item.id, item.type])
  const refactoredUsers = props.users.map((item) => [item.id, item.name])
  const [newCustomer, setNewCustomer] = useState(props.asset.customer_id ?? '-')
  const [newAssetTpe, setNewAssetType] = useState(props.asset.asset_type_id ?? '-')
  const [newUser, setNewUser] = useState(props.asset.user_id ?? '-')

  useEffect(() => {
    setNewAssetType(props.asset.asset_type_id)
  }, [props.asset.asset_type_id]);
  useEffect(() => {
    setNewCustomer(props.asset.customer_id)
  }, [props.asset.customer_id]);
  useEffect(() => {
    setNewUser(props.asset.user_id ?? '-')
  }, [props.asset.user_id]);
  const reload = useRefresh()


  const selectedCustomer = props.allCustomers.find(c => c.id === newCustomer)
  const selectedUser = props.users.find(u => u.id === newUser)
  const selectedAssetType = props.assetTypes.find(t => t.id === newAssetTpe)

  const saveNewValues = useCallback(async (customer?: string, user?: string, assetType?: string) => {
    const development = await editAsset(
      props.asset.id,
      {
        customer_id: customer,
        user_id: user,
        asset_type_id: assetType
      })
    reload()
  }, []);

  if (assetDetailsSidebarBeta) {
    return <div className={'mb-8 ml-6'}>
      <div className={'sticky top-4 w-72 rounded border border-slate-300 dark:border-zinc-500 p-2 space-x-2 bg-white dark:bg-zinc-700'}>

        <div className={'-mx-2 px-4 pb-4 mb-2 border-b border-slate-300 dark:border-zinc-500'}>
          <div className={'text-sm font-medium mb-2'}>Klant</div>
          <IconDropdownButton type={"secondary"} size={"sm"} text={selectedCustomer?.name ?? '-'} data={Object.fromEntries(refactoredCustomers)} onClick={ async (key) => {
            setNewCustomer(key)
            await saveNewValues(key, newUser, newAssetTpe)
          }}/>
        </div>

        <div className={'-mx-2 px-4 pb-4 mb-2 border-b border-slate-300 dark:border-zinc-500'}>
          <div className={'text-sm font-medium mb-2'}>Gebruiker</div>
          <IconDropdownButton type={'secondary'} size={'sm'} text={selectedUser?.name ?? '-'} data={Object.fromEntries(refactoredUsers)} onClick={ async (key) => {
            setNewUser(key)
            await saveNewValues(newCustomer, key, newAssetTpe)
          }}/>
        </div>


        <div className={'-mx-2 px-4 pb-4 mb-2 border-b border-slate-300 dark:border-zinc-500'}>
          <div className={'text-sm font-medium mb-2'}>Asset type</div>
          <IconDropdownButton type={'secondary'} size={'sm'} text={selectedAssetType?.type ?? '-'} data={Object.fromEntries(refactoredAssetTypes)} onClick={ async (key) => {
            setNewAssetType(key)
            await saveNewValues(newCustomer, newUser, key)
          }}/>
        </div>

      </div>
    </div>
  }

  return <>

  </>
}