import {Customer, Ticket, TicketPriority, TicketRelation} from "../api/dto";
import React, {FC, Fragment, useCallback, useEffect, useRef, useState} from "react";
import {ModalFooter, useModal} from "../components/layout/ModalProvider";
import {usePermission} from "../permissions/PermissionContext";
import {EditDescriptionModal} from "./EditDescriptionModal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPenToSquare} from "@fortawesome/free-regular-svg-icons";
import {useTenant} from "../tenant/TenantContext";
import {ErrorBag, useApiCall, ValidationError} from "../api/api";
import {IconDropdownButton} from "../components/form/IconButtonDropdown";
import {faLink} from "@fortawesome/pro-regular-svg-icons";
import {TicketStatusLabel} from "../components/tickets/TicketListCard";
import {NavLink} from "react-router-dom";
import {Button, IconButton} from "../components/form/Button";
import {faChevronDown, faChevronUp, faPaperclip, faTimes} from "@fortawesome/free-solid-svg-icons";
import {useRefresh, useRefreshEffect} from "../components/RefreshController";
import {useTicketRefs} from "../util/useTicketRef";
import {Input} from "../components/form/Input";
import {InputErrors} from "../components/form/InputErrors";
import {usePersistentState} from "../util/usePersistentState";
import {Select} from "../components/form/Select";
import {Autocomplete} from "../components/form/Autocomplete";
import {useFetchedResource} from "../api/APIContext";

export const ProblemTicketModal: FC<{ticket: Ticket, otherTickets: Ticket[], priorities: TicketPriority[]}> = (props) => {
  const {getCustomers} = useApiCall();
  const {resource: customers, reload: reloadCustomers} = useFetchedResource(() => getCustomers())

  useRefreshEffect(() => {
    reloadCustomers(undefined)
  })
  return <div>
    {customers && <CreateProblemTicketForm ticket={props.ticket} otherTickets={props.otherTickets} priorities={props.priorities} customers={customers}/>}
  </div>
}

const CreateProblemTicketForm: FC<{ticket: Ticket, otherTickets: Ticket[], priorities: TicketPriority[], customers: Customer[]}> = (props) => {
  const {addProblemTicket} = useApiCall();
  const [input, setInput] = useState(`#${props.ticket.number}\n`)
  const [title, setTitle] = usePersistentState<string>('add_ticket_title', '')
  const [description, setDescription] = usePersistentState<string>('add_ticket_description', '')
  const [priority, setPriority] = useState(props.priorities[0].id)
  const [customer, setCustomer] = useState('')
  const refactoredPriorities = props.priorities.map((item) => [item.id, item.priority])
  const refactoredCustomers = props.customers.filter(c => c.deletedAt === null).map((item) => [item.id, item.name])
  const fileInputRef = useRef<HTMLInputElement>(null)
  const reload = useRefresh();
  const [errors, setErrors] = useState<ErrorBag>({})

  const [ignoreReferences, setIgnoreReferences] = useState<string[]>([])
  const references = useTicketRefs(props.otherTickets, input).filter(t => !ignoreReferences.includes(t.id))

  const save = useCallback( async () => {
    const promise = addProblemTicket({
      ticket_uuid: props.ticket.id,
      subject: title,
      description: description,
      priority_uuid: priority,
      customer_uuid: customer,
      references: references.filter(t => t.id !== props.ticket.id).map(r => r.id),
    }).catch(error => {
      if (error instanceof ValidationError) {
        setErrors(error.errors)
        throw new Error('Validation error')
      }
      throw error
    })
    setDescription('')
    setTitle('')
    await promise
    reload()
  }, [title, description, priority, customer, references])

  return <>
    <div className={'grid grid-cols-1 lg:grid-cols-4 gap-4'}>
      <div className={"col-span-1"}>
        <Select label={"Prioriteit"} options={Object.fromEntries(refactoredPriorities)} value={priority}
                onChange={(v) => setPriority(v)}/>
      </div>
      <div className={"items-center col-span-3"}>
        <Input label={"Titel"} type={"text"} value={title} onChange={(v) => setTitle(v)}/>
        <InputErrors errors={errors} field={'subject'}/>
      </div>
    </div>
    <div className={'grid grid-cols-1 lg:grid-cols-4 gap-4 my-4'}>
      <div className={'col-span-2'}>
        <Autocomplete label={"Klant"} options={Object.fromEntries(refactoredCustomers)} value={customer}
                      onChange={(v) => setCustomer(v)}/>
      </div>
    </div>
    <div className={"items-center"}>
      <Input label={"Beschrijving"} type={"textarea"} value={description} onChange={(v) => setDescription(v)}/>
      <InputErrors errors={errors} field={'description'}/>
    </div>
    {/* related */}
    <div className={"my-4"}>
      <form className={"flex flex-col items-stretch"}>
        <Input label={"Koppel problem ticket aan:"} type={"textarea"} placeholder={'Plak hier de gerelateerde ticket nummers of links...'} value={input} onChange={(v) => setInput(v)}/>
        {references.length > 0 && <div className={"mt-4 flex flex-col items-stretch"}>
          <h2 className={'text-sm font-medium'}>Koppel dit ticket aan:</h2>
          {references.map((ticket, i) => {
            return <button type={'button'} key={i} className={'px-2 py-1 border border-slate-300 dark:border-brand-700 rounded text-sm flex items-center justify-between mt-2 hover:bg-slate-50 hover:dark:bg-zinc-600 hover:text-red-800 hover:dark:text-red-500'} onClick={() => setIgnoreReferences((old) => [...old, ticket.id])}>
              <span className={'font-medium opacity-80 mr-1'}>#{ticket.number}</span>
              <span className={'text-left flex-1'}>&nbsp;&middot;&nbsp;{(ticket.subject?.length ?? 0) > 32 ? ticket.subject?.slice(0, 32) + '...' : ticket.subject}&nbsp;&middot;&nbsp;</span>
              <span className={'opacity-80 text-left mr-2'}>{ticket.customer?.name}</span>
              <FontAwesomeIcon icon={faTimes} />
            </button>
          })}
        </div>}
      </form>
    </div>
    <ModalFooter text={"Aanmaken"} onSubmit={save}/>
  </>
}
