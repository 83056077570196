import React, {FC, useState} from "react";
import {ContentContainer} from "../components/content/ContentContainer";
import {PageIconHeader} from "../components/layout/PageIconHeader";
import {Breadcrumbs} from "../components/content/Breadcrumbs";
import {PageHeader} from "../components/content/PageHeader";
import {faAdd, faBoxes, faCookieBite, faFilter} from "@fortawesome/free-solid-svg-icons";
import {useTenant} from "../tenant/TenantContext";
import {usePermission} from "../permissions/PermissionContext";
import {Button} from "../components/form/Button";
import {useFetchedResource} from "../api/APIContext";
import {useApiCall} from "../api/api";
import {useRefreshEffect} from "../components/RefreshController";
import {useModal} from "../components/layout/ModalProvider";
import {AddAssetModal} from "../modals/AddAssetModal";
import {SearchControlBar} from "../components/assets/SearchControlBar";
import {Asset, AssetType, Customer} from "../api/dto";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {SimplePaginate} from "../components/data/SimplePaginate";
import {AssetListCard} from "../components/assets/AssetListCard";

export const Assets: FC = () => {
  const {tenant} = useTenant()
  const {getAssets, getCustomers, getAssetTypes} = useApiCall()
  const {resource: assetResource, loading: loadingAssets, reload: reloadAssets} = useFetchedResource(() => getAssets())
  const {resource: customerResource, loading: loadingCustomers, reload: reloadCustomers} = useFetchedResource(() => getCustomers())
  const {resource: assetTypeResource, loading: loadingAssetTypes, reload: reloadAssetTypes} = useFetchedResource(() => getAssetTypes())

  useRefreshEffect(() => {
    reloadAssets(undefined)
    reloadCustomers(undefined)
    reloadAssetTypes(undefined)
  })
  const {open: openCreateModal} = useModal({title: 'Asset toevoegen', body: <AddAssetModal customers={customerResource ?? []} assetTypes={assetTypeResource ?? []} />, size: "md"})
  return (
    <ContentContainer size={"xl"}>
      <div className={"flex justify-between items-end"}>
        <PageIconHeader icon={faBoxes}>
          <Breadcrumbs crumbs={[
            {label: 'Asset Management', href: `/${tenant}/asset-management/assets`}
          ]} currentPage={"Assets"} />
          <PageHeader>Assets</PageHeader>
        </PageIconHeader>

        {usePermission().canAddAsset() &&
          <div className={"flex space-x-4 mb-8"}>
            <Button size={"md"} type={"primary"} text={"Asset toevoegen"} icon={faAdd}
                    disabled={loadingAssets}
                    onClick={() => openCreateModal()}/>
          </div>
        }
      </div>

      {loadingAssets ? <>
        Loading assets...
      </> : <>
        {tenant && assetResource && assetTypeResource && customerResource && <>
          <AssetList assets={assetResource} assetTypes={assetTypeResource} customers={customerResource} tenantSlug={tenant} />
        </>}
      </>}

    </ContentContainer>
  )
}

const AssetList: FC<{assets: Asset[], assetTypes: AssetType[], customers: Customer[], tenantSlug: string}> = (props) => {
  const [searchedAssets, setSearchedAssets] = useState<Asset[]>([])
  return <div>
    <SearchControlBar
      assets={props.assets}
      assetTypes={props.assetTypes}
      customers={props.customers}
      tenantSlug={props.tenantSlug}
      onSearchedAssetsChange={(assets) => setSearchedAssets(assets)}
    />
    {searchedAssets.length === 0 && <div className={"border border-slate-200 dark:border-zinc-500 rounded h-64 flex flex-col items-center justify-center text-slate-600 dark:text-zinc-300 text-lg font-medium"}>
      <FontAwesomeIcon icon={searchedAssets.length > 0 ? faFilter : faCookieBite} className={'mb-8 text-4xl'} />
      {searchedAssets.length > 0 ? <>
        <h2>Er zijn nog {searchedAssets.length} assets die niet door je zoekopdracht of filter heen komen.</h2>
        <p className={"font-normal text-regular mt-4"}>Verbreed het filter/zoekopdracht om meer assets te zien</p>
      </> : <>
        <h2>Er zijn nog geen assets!</h2>
      </>}
    </div>}
    <SimplePaginate paginationLimit={50} data={searchedAssets} resultsBuilder={(paginatedAssets) => {
      const sortedPaginatedAssets = paginatedAssets.sort((a, b) => {
        return a.created_at > b.created_at ? -1: 1;
      })
      return <>{sortedPaginatedAssets.map((asset, i) => <React.Fragment key={i}><AssetListCard
        asset={asset}
        assetType={props.assetTypes.find(type => type.id === asset.asset_type_id) ?? null}
        customer={props.customers.find(customer => customer.id === asset.customer_id) ?? null}
        isFirst={i === 0}
        isLast={i === (paginatedAssets.length - 1)}
        /></React.Fragment>)}</>
    }}/>
  </div>
}