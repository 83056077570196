import {FC, useCallback, useState} from "react";
import {ModalFooter} from "../components/layout/ModalProvider";
import {faSave} from "@fortawesome/free-solid-svg-icons";
import {Input} from "../components/form/Input";
import {InputErrors} from "../components/form/InputErrors";
import {ErrorBag, useApiCall, ValidationError} from "../api/api";
import {Autocomplete} from "../components/form/Autocomplete";
import {AssetType, Customer} from "../api/dto";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleMinus, faCirclePlus} from "@fortawesome/pro-regular-svg-icons";
import {useNavigate} from "react-router-dom";
import {useRefresh} from "../components/RefreshController";

interface CustomFields {
  key: string
  value: string
}

export const AddAssetModal: FC<{customers: Customer[], assetTypes: AssetType[]}> = (props) => {
  const {addAsset} = useApiCall()
  const [name, setName] = useState<string>('')
  const [customer, setCustomer] = useState('')
  const [type, setType] = useState('')
  const [purchaseDate, setPurchaseDate] = useState(new Date())
  const [deliveryDate, setDeliveryDate] = useState(new Date())
  const [price, setPrice] = useState('')
  const [model, setModel] = useState('')
  const [brand, setBrand] = useState('')
  const [serialNumber, setSerialNumber] = useState('')
  const refactoredCustomers = props.customers.filter(c => c.deletedAt === null).map((item) => [item.id, item.name])
  const refactoredTypes = props.assetTypes.map((item) => [item.id, item.type])
  const [errors, setErrors] = useState<ErrorBag>({})
  const [customFields, setCustomFields] = useState<CustomFields[]>([])

  const navigate = useNavigate()
  const reload = useRefresh()

  const setFieldState = (index: number, type: 'key'|'value', value: string)=> {
    setCustomFields(old => old.map((oldEntry, k) => {
      if (k !== index) {
        return oldEntry
      }
      return {
        ...oldEntry,
        [type]: value
      }
    }))
  }

  const addNewItem = () => {
    const newItem = {
      key: '',
      value: ''
    };
    setCustomFields((prevCustomEntry) => [...prevCustomEntry, newItem])
  }

  const removeItem = (index: number) => {
    setCustomFields((prevCustomEntry) => prevCustomEntry.filter((_, i) => i !== index))
  }

  const save = useCallback(async () => {
    await addAsset({
      name: name,
      customer_uuid: customer,
      asset_type_uuid: type,
      purchased_at: purchaseDate,
      delivered_at: deliveryDate,
      price: price,
      model: model,
      brand: brand,
      serial_number: serialNumber
    }).catch(error => {
      if (error instanceof ValidationError) {
        setErrors(error.errors)
        throw new Error('Validation error')
      }
      throw error
    })
    reload();
  }, [name, customer, type, purchaseDate, deliveryDate, price, model, brand, serialNumber])

  return <>
    <form className={"space-y-4"} onSubmit={save}>

      <div className={""}>
        <Input type={'text'} label={'naam'} value={name} onChange={(x) => setName(x)} required={true} />
        <InputErrors errors={errors} field={'name'} />
      </div>

      <div className={"grid grid-cols-2 gap-4"}>
        <div className={"col-span-1"}>
          <Autocomplete label={'Klant'} options={Object.fromEntries(refactoredCustomers)} value={customer} onChange={(c) => setCustomer(c)} required={true} />
          <InputErrors errors={errors} field={'customer'} />
        </div>
        <div className={'col-span-1'}>
          <Autocomplete label={'Type'} options={Object.fromEntries(refactoredTypes)} value={type} onChange={(t) => setType(t)} required={true} />
          <InputErrors errors={errors} field={'type'} />
        </div>
      </div>

      {props.assetTypes.find(t => t.id === type)?.isPhysicalProduct ? <>
        <div className={'grid grid-cols-3 gap-4'}>
          <div className={"col-span-1"}>
            <Input type={'text'} label={'Merk'} value={brand} onChange={(x) => setBrand(x)} />
            <InputErrors errors={errors} field={'brand'} />
          </div>
          <div className={"col-span-1"}>
            <Input type={'text'} label={'Model'} value={model} onChange={(x) => setModel(x)} />
            <InputErrors errors={errors} field={'model'} />
          </div>
          <div className={"col-span-1"}>
            <Input type={'text'} label={'Serie nummer'} value={serialNumber} onChange={(x) => setSerialNumber(x)} />
            <InputErrors errors={errors} field={'serial_number'} />
          </div>
        </div>
      </> : <> </>}

      <div className={'grid grid-cols-3 gap-4'}>
        <div className={"col-span-1"}>
          <Input type={'text'} label={'Prijs'} value={price} onChange={(x) => setPrice(x)} />
          <InputErrors errors={errors} field={'price'} />
        </div>
        <div className={"col-span-1"}>
          <Input type={'date'} label={'Aankoopddatum'} value={purchaseDate} onChange={setPurchaseDate} />
          <InputErrors errors={errors} field={'purchased_at'} />
        </div>
        <div className={"col-span-1"}>
          <Input type={'date'} label={'Leverdatum'} value={deliveryDate} onChange={setDeliveryDate} />
          <InputErrors errors={errors} field={'delivered_at'} />
        </div>
      </div>
      
      {/* Optional fields */}
      <div>
        {customFields.length > 0 && <>
          <h1 className={'mt-4 mb-2'}>Extra velden</h1>
          {customFields.map((fields, i) => {
            return <div className={'flex flex-row items-center content-center space-x-3 mb-3'}>
            <div className={"flex-1"}>
              <Input type={'text'} label={''} value={fields.key ?? ''} onChange={(x) => setFieldState(i, 'key', x)} />
            </div>
            <div className={"flex-1"}>
              <Input type={'text'} label={''} value={fields.value ?? ''} onChange={(x) => setFieldState(i, 'value', x)} />
            </div>
              <FontAwesomeIcon icon={faCircleMinus} className={'text-red-600 max-w-8 px-3 py-3 hover:bg-red-300 hover:dark:bg-zinc-600 hover:cursor-pointer rounded'}
                onClick={() => removeItem(i)}/>
            </div>
          })}
        </>}

        {/* Add additional fields */}
        {/*TODO: uncomment when decision has been made over custom field templates.*/}
        {/*<div className={'flex space-x-3 items-center justify-center text-brand-300 dark:text-brand-500 hover:text-brand-400 hover:dark:text-brand-400 hover:bg-slate-300 hover:dark:bg-zinc-600 rounded hover:cursor-pointer py-2 px-3'}*/}
        {/*      onClick={addNewItem}>*/}
        {/*  <span>Extra veld</span>*/}
        {/*  <FontAwesomeIcon icon={faCirclePlus} />*/}
        {/*</div>*/}

      </div>
    </form>
    <ModalFooter text={'Opslaan'} icon={faSave} onSubmit={save} />
  </>
}