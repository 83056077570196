import {FC, useState} from "react";
import {Asset, AssetType, Customer} from "../../api/dto";
import {NavLink, useNavigate} from "react-router-dom";
import {useTenant} from "../../tenant/TenantContext";
import {CustomerAvatar} from "../content/CustomerAvatar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBagShopping} from "@fortawesome/pro-light-svg-icons";
import {faChevronRight, faTag, faBarcode, faBuilding} from "@fortawesome/free-solid-svg-icons";

export const AssetListCard: FC<{asset: Asset, assetType: AssetType|null, customer: Customer|null, isFirst: boolean, isLast: boolean, limited?: boolean}> = (props) => {
  const navigate = useNavigate()
  const {tenant} = useTenant()
  const borderStyle = `border dark:border-zinc-500 ${props.isLast ? 'rounded-b' : 'border-b-0'} ${props.isFirst ? 'rounded-t' : ''}`
  const [selected, setSelected] = useState<boolean>(false)

  const toAsset = () => navigate(`/${tenant}/asset-management/assets/${props.asset.id}`)

  return <NavLink className={`${borderStyle} ${selected ? 'bg-brand-50 dark:bg-zinc-600' : 'bg-white dark:bg-zinc-700 hover:bg-slate-50 hover:dark:bg-zinc-600'} cursor-pointer flex items-stretch content-center py-1`} to={`/${tenant}/asset-management/assets/${props.asset.id}`}>
    <div className={"flex items-center justify-center"}>
      <label className={"flex items-center justify-center m-2 w-10 h-10 border border-transparent hover:border-brand-500 hover:dark:border-brand-600 hover:bg-brand-100 hover:dark:bg-zinc-500 rounded-lg cursor-pointer"} onClick={(e) => {
        e.preventDefault()
        setSelected(!selected)
      }}>
        <input type="checkbox" className={"cursor-pointer accent-brand-700"} checked={selected} onClick={() => {
          setSelected(!selected)
        }} />
      </label>
    </div>
    <div className={"pr-4 flex items-center justify-center"} onClick={() => toAsset()}>
      {props.customer ? <CustomerAvatar customer={props.customer}/> : <UnassignedAssetCustomerAvatar />}
    </div>

    <div className={"flex-1 py-3 space-y-3"} onClick={() => toAsset()}>
      <div className={'grid grid-cols-12 content-center'} onClick={() => toAsset()}>
        <div className={'col-span-2'}>
          <span className={"text-sm text-gray-100 dark:text-zinc-300 font-medium whitespace-nowrap"}>{formatAssetNumber(props.asset.asset_nr)}</span>
        </div>
        <div className={'col-span-2'}>
          <span className={"text-sm font-medium px-2 py-1 mr-2 rounded border"}>{props.assetType?.type}</span>
        </div>
        {props.asset.customer_id &&
          <div className={'col-span-2'}>
            <span className={"font-medium"}><FontAwesomeIcon icon={faBuilding} className={"mr-2"}/>{props.customer?.name}</span>
          </div>
        }
      </div>

      <div className={'grid grid-cols-10 content-center'} onClick={() => toAsset()}>
        <div className={'col-span-2'}>
          <span className={"font-medium"}><FontAwesomeIcon icon={faTag} className={"mr-2"}/>{props.asset.name}</span>
        </div>
        {props.asset.model && props.asset.brand && <div className={'col-span-2'}>
          <span className={''}><FontAwesomeIcon icon={faBagShopping} className={"mr-2"}/>{`${props.asset.brand} ${props.asset.model}`}</span>
        </div>
        }
        <div className={'col-span-3'}>
          <span className={"font-medium"}><FontAwesomeIcon icon={faBarcode} className={"mr-2"}/>{props.asset.serial_number}</span>
        </div>
      </div>

    </div>

    <div className={"px-4 flex items-center justify-center"} onClick={() => toAsset()}>
      <FontAwesomeIcon icon={faChevronRight} className={'text-brand-500 dark:text-brand-600'}/>
    </div>
  </NavLink>
}

const UnassignedAssetCustomerAvatar: FC = () => {
  return <div className={"flex items-center justify-center h-10 w-10 text-sm font-medium text-brand-600 dark:text-brand-200 bg-brand-100 dark:bg-brand-700 border border-brand-100 dark:border-brand-800 rounded my-2"}>
    ??
  </div>
}

function formatAssetNumber(assetNr: string): string {
  let regex = /(\d{4})(\d{4})(\d{4})/;
  const result = assetNr.replace(regex, '$1 $2 $3');
  return `#${result}`;
}
