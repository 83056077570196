import {FC} from "react";
import {useTenant} from "../tenant/TenantContext";
import {useApiCall} from "../api/api";
import {useRefreshEffect} from "../components/RefreshController";
import {ContentContainer} from "../components/content/ContentContainer";
import {PageIconHeader} from "../components/layout/PageIconHeader";
import {faAdd, faCheck, faChevronRight, faGear, faXmark} from "@fortawesome/free-solid-svg-icons";
import {Breadcrumbs} from "../components/content/Breadcrumbs";
import {PageHeader} from "../components/content/PageHeader";
import {TabContainer} from "../components/layout/TabContainer";
import {Callout} from "../components/content/Callout";
import {SectionHeader} from "../components/content/SectionHeader";
import {Paragraph} from "../components/Paragraph";
import {Button} from "../components/form/Button";
import {AssetType} from "../api/dto";
import {useNavigate, useParams} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useFetchedResource} from "../api/APIContext";
import {useModal} from "../components/layout/ModalProvider";
import {AddAssetTypeModal} from "../modals/AddAssetTypeModal";
import {EditAssetTypeModal} from "../modals/EditAssetTypeModal";

export const AssetFlow: FC = () => {
  const tenant = useTenant()
  const {getAssetTypes} = useApiCall()
  const {resource: assetTypeResource, loading: loadingAssetTypes, reload: reloadAssetTypes} = useFetchedResource(() => getAssetTypes())

  useRefreshEffect(() => {
    reloadAssetTypes(undefined)
  })

  return <ContentContainer>
    <PageIconHeader icon={faGear}>
      <Breadcrumbs crumbs={[
        {label: "Asset Management", href: `/${tenant.tenant}/asset-management/assets`},
      ]} currentPage={'Instellingen'} />
      <PageHeader>Instellingen</PageHeader>
    </PageIconHeader>

    <TabContainer tabs={[
      {'name': 'Types', 'body': <>
          <AddTypeForm />
          {assetTypeResource && <>
            <TypeTable types={assetTypeResource} />
          </>}
        </>},
    ]} />
  </ContentContainer>
}

const AddTypeForm: FC = () => {
  const {open: openAddTypeModal} = useModal({title: 'Asset type toevoegen', body: <AddAssetTypeModal />, size: 'md'})
  return <Callout>
    <SectionHeader>Voeg een nieuw asset type toe</SectionHeader>
    <Paragraph>
      Maak een nieuw asset type aan, zodat je jouw assets overzichtelijk kan groeperen.
    </Paragraph>
    <Button type={'primary'} size={'sm'} text={'Asset type toevoegen'} icon={faAdd} onClick={() => openAddTypeModal()} />
  </Callout>
}

const TypeTable: FC<{types: AssetType[]}> = props => {
  return <>
    <div className="my-4 align-middle inline-block min-w-full">
      <div className={"overflow-hidden border-2 border-slate-100 dark:border-zinc-600 sm:rounded-lg bg-white dark:bg-zinc-700"}>
        <table className={"min-w-full"}>
          <thead className={"border-b border-slate-200 dark:border-zinc-600 p-2"}>
          <tr>
            <th className={"py-3 px-4 text-left text-slate-500 dark:text-zinc-300"}>Types</th>
            <th className={"py-3 px-4 text-left text-slate-500 dark:text-zinc-300"}>Aantal devices</th>
            <th className={"py-3 px-4 text-left text-slate-500 dark:text-zinc-300"}>Fysiek product</th>
            <th className={"w-4 py-3 px-4 text-left text-slate-500 dark:text-zinc-300"}></th>
          </tr>
          </thead>
          <tbody>
          {props.types.map((prop, i) => {
            return <TypeTableRow type={prop} key={i}/>
          })}
          </tbody>
        </table>
      </div>
    </div>
  </>
}

const TypeTableRow: FC<{type: AssetType}> = props => {
  const {tenant} = useParams()
  const navigate = useNavigate();
  const {open: openEditModal} = useModal({title: 'Asset type bewerken', body: <EditAssetTypeModal assetType={props.type} />, size: 'md'})
  return <>
    <tr className={"hover:bg-brand-100 hover:dark:bg-zinc-600 hover:cursor-pointer border border-slate-100 dark:border-zinc-600"} onClick={() => {openEditModal()}}>
      <td className={"py-3 px-4 text-left"}>{props.type.type}</td>
      <td className={"py-3 px-4 text-left"}>0</td>
      <td className={`py-3 px-4 text-left ${props.type.isPhysicalProduct ? 'text-green-600' : 'text-red-600'}`}>{props.type.isPhysicalProduct ? <FontAwesomeIcon icon={faCheck}/> : <FontAwesomeIcon icon={faXmark} />}</td>
      <td className={"py-3 px-4 text-left"}><span className={"flex flex-row items-center justify-center text-sm text-brand-700"}>DETAILS <FontAwesomeIcon className={"mx-1"} icon={faChevronRight}/></span></td>
    </tr>
  </>
}