import React, {FC, useState} from "react";
import {ModalFooter} from "../components/layout/ModalProvider";
import {useRefresh} from "../components/RefreshController";
import {ErrorBag, useApiCall, ValidationError} from "../api/api";
import {Asset, Contract, TicketPriority} from "../api/dto";
import {useNavigate} from "react-router-dom";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {InputErrors} from "../components/form/InputErrors";
import {useTenant} from "../tenant/TenantContext";

export const DeleteAssetModal: FC<{asset: Asset}> = (props) => {
  const {deleteAsset} = useApiCall()
  const reload = useRefresh()
  const navigate = useNavigate()
  const [errors, setErrors] = useState<ErrorBag>({})
  const {tenant} = useTenant()

  const confirm = async () => {
    const deployment = await deleteAsset(props.asset.id).catch(error => {
      if (error instanceof ValidationError) {
        setErrors(error.errors)
        throw new Error('Validation error')
      }
      throw error
    })
    reload()
    navigate(`${tenant}/asset-management/assets`)
  }

  return <>
    <p>
      <strong>Let op!</strong> Je staat op het punt om het <strong>{props.asset.name}</strong> asset te
      verwijderen uit jouw omgeving.
    </p>
    <p>
      <strong>Weet je het zeker?</strong>
    </p>
    <InputErrors errors={errors} field={'error'}/>
    <ModalFooter icon={faTrash} text={"Ja, verwijderen"} onSubmit={confirm}/>
  </>
}