import React, {FC, useEffect} from "react";
import {Asset, AssetType, Customer, User} from "../api/dto";
import {useTenant} from "../tenant/TenantContext";
import {useParams} from "react-router-dom";
import {useApiCall} from "../api/api";
import {ContentContainer} from "../components/content/ContentContainer";
import {PageIconHeader} from "../components/layout/PageIconHeader";
import {faBoxes, faCopy, faTrash} from "@fortawesome/free-solid-svg-icons";
import {Breadcrumbs} from "../components/content/Breadcrumbs";
import {useFetchedResource} from "../api/APIContext";
import {useLatestRefreshTime, useRefreshEffect} from "../components/RefreshController";
import {PageHeader} from "../components/content/PageHeader";
import {copyToClipboard} from "../util/copyToClipboard";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import moment from "moment";
import {Button} from "../components/form/Button";
import {faPenToSquare} from "@fortawesome/free-regular-svg-icons";
import {AssetButtons} from "../components/assets/AssetButtons";
import {useLocalSettings} from "../components/LocalSettingsContext";
import {useModal} from "../components/layout/ModalProvider";
import {EditAssetModal} from "../modals/EditAssetModal";
import {DeleteAssetModal} from "../modals/DeleteAssetModal";

export const AssetDetails: FC = (props) => {
  const {tenant} = useTenant();
  const {asset} = useParams()
  const {getAsset, getCustomers, getAllUsersForTenant, getAssetTypes} = useApiCall()
  const {resource: assetResource, loading: loadingAsset, reload: reloadAsset} = useFetchedResource((assetId: string) => getAsset(assetId))
  const {resource: customerResource, loading: loadingCustomers, reload: reloadCustomers} = useFetchedResource(() => getCustomers())
  const {resource: tenantUsers, loading: loadTenantUsers, reload: reloadTenantUsers} = useFetchedResource(() => getAllUsersForTenant())
  const {resource: assetTypeResource, loading: loadAssetTypes, reload: reloadAssetTypes} = useFetchedResource(() => getAssetTypes())
  const latestRefreshTime = useLatestRefreshTime()

  console.log(assetResource)
  useEffect(() => {
    if (asset === undefined) {
      console.warn('asset uuid is undefined')
      return
    }
    reloadAsset(asset)
  }, [asset, latestRefreshTime]);

  useRefreshEffect(() => {
    reloadCustomers(undefined)
    reloadTenantUsers(undefined)
    reloadAssetTypes(undefined)
  })

  return <ContentContainer size={'xl'}>
    <PageIconHeader icon={faBoxes} backButton={true}>
      <Breadcrumbs crumbs={[
          {label: 'Asset Management', href: `/${tenant}/asset-management/assets`},
          {label: 'Assets', href: `/${tenant}/asset-management/assets`}
        ]} currentPage={assetResource?.name ?? "Asset details"}>
      </Breadcrumbs>

      <PageHeader>{assetResource?.asset_nr && assetResource?.name ? `#${assetResource.asset_nr} - ${assetResource.name}` : ''}
      {assetResource && <span className={"pl-4 dark:text-zinc-700 hover:cursor-pointer hover:dark:text-zinc-400 text-slate-300 hover:text-slate-500 text-base"} onClick={() => copyToClipboard(formatAssetSlug(assetResource))}>
          <FontAwesomeIcon icon={faCopy}/>
        </span>}
      </PageHeader>

    </PageIconHeader>

    {assetResource && customerResource && tenantUsers && assetTypeResource &&
      <DetailsCard asset={assetResource} customers={customerResource} users={tenantUsers} assetTypes={assetTypeResource} />
    }

  </ContentContainer>
}

const DetailsCard: FC<{ asset: Asset, customers: Customer[], users: User[], assetTypes: AssetType[] }> = (props) => {
  const {assetDetailsSidebarBeta} = useLocalSettings()
  const {open: openEditModal} = useModal({title: 'Asset bewerken', body: <EditAssetModal asset={props.asset} customers={props.customers} assetTypes={props.assetTypes} users={props.users} />, size:'lg'})
  const {open: openDeleteModal} = useModal({title: 'Asset verwijderen', body: <DeleteAssetModal asset={props.asset} />, size:'md'})
  return <div>
    <div className={"flex mb-4 items-center justify-between"}>
      <h1 className={"text-xl"}>Details</h1>
      <div className={"flex space-x-2"}>
        <Button type={'primary'} size={'md'} text={'Bewerk'} icon={faPenToSquare} onClick={() => openEditModal()} />
        <Button type={'danger'} size={'md'} text={'Verwijder'} icon={faTrash} onClick={() => openDeleteModal()} />
      </div>
    </div>

    <div className={assetDetailsSidebarBeta ? 'flex flex-row-reverse items-stretch' : 'flex flex-col items-stretch'}>
      <AssetButtons asset={props.asset} allCustomers={props.customers} users={props.users} assetTypes={props.assetTypes} />

      <div className={'flex-1'}>
        <div className={"grid grid-cols-8 bg-white dark:bg-zinc-700 border border-slate-200 dark:border-zinc-500 rounded py-2 px-3"}>
          <div className={"col-span-4"}>
            <div className={"flex-col space-y-4"}>

              <div className={"grid grid-cols-3 gap-5"}>
                <div className={"col-span-1"}>
                  <dl className={"flex-1"}>
                    <dt className={"text-sm font-medium"}>Brand</dt>
                    <dd
                      className={"text-brand-900 dark:text-brand-200"}>{props.asset.brand}</dd>
                  </dl>
                </div>
                <div className={"col-span-1"}>
                  <dl className={"flex-1"}>
                    <dt className={"text-sm font-medium"}>Model</dt>
                    <dd
                      className={"text-brand-900 dark:text-brand-200"}>{props.asset.model}</dd>
                  </dl>
                </div>
                <div className={"col-span-1"}>
                  <dl className={"flex-1"}>
                    <dt className={"text-sm font-medium"}>Serie nummer</dt>
                    <dd
                      className={"text-brand-900 dark:text-brand-200"}>{props.asset.serial_number}</dd>
                  </dl>
                </div>
              </div>

              <div className={"grid grid-cols-3 gap-5"}>
                <div className={"col-span-1"}>
                  <dl className={"flex-1"}>
                    <dt className={"text-sm font-medium"}>Aankoop datum</dt>
                    <dd
                      className={"text-brand-900 dark:text-brand-200"}>{moment(props.asset.purchased_at).format('DD-MM-yyyy') ?? '-'}</dd>
                  </dl>
                </div>

                <div className={"col-span-1"}>
                  <dl className={"flex-1"}>
                    <dt className={"text-sm font-medium"}>Lever datum</dt>
                    <dd
                      className={"text-brand-900 dark:text-brand-200"}>{moment(props.asset.delivered_at).format('DD-MM-yyyy') ?? '-'}</dd>
                  </dl>
                </div>
                <div className={"col-span-1"}>
                  <dl className={"flex-1"}>
                    <dt className={"text-sm font-medium"}>Prijs</dt>
                    <dd
                      className={"text-brand-900 dark:text-brand-200"}>{props.asset.price ?? '-'}</dd>
                  </dl>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

    </div>

  </div>
}

function formatAssetSlug(asset: Asset): string {
  if (! asset.name) {
    return asset.asset_nr
  }
  return `${asset.asset_nr}-${asset.name}`.replaceAll(' ', '-').replaceAll(/(\W+)/g, '-').toLowerCase()
}

