import React, {FC, useState} from "react";
import {ModalFooter} from "../components/layout/ModalProvider";
import {useRefresh} from "../components/RefreshController";
import {ErrorBag, useApiCall, ValidationError} from "../api/api";
import {Team, Ticket, TicketPriority} from "../api/dto";
import {useNavigate} from "react-router-dom";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {InputErrors} from "../components/form/InputErrors";
import {useTenant} from "../tenant/TenantContext";

export const ArchiveTicketModal: FC<{ ticket: Ticket }> = (props) => {
  const {deleteTicket} = useApiCall()
  const {tenant} = useTenant()
  const reload = useRefresh()
  const navigate = useNavigate()
  const [errors, setErrors] = useState<ErrorBag>({})

  const confirm = async () => {
    const deployment = await deleteTicket(props.ticket.id).catch(error => {
      if (error instanceof ValidationError) {
        setErrors(error.errors)
        throw new Error('Validation error')
      }
      throw error
    })
    reload()
    navigate(`/${tenant}/ticket`)
  }

  return <>
    <p>
      <strong>LET OP!</strong> je probeert nu <strong>{props.ticket.subject}</strong> uit je omgeving te verwijderen.
    </p>
    <InputErrors errors={errors} field={'error'} />
      <ModalFooter icon={faTrash} text={"Delete"} onSubmit={confirm}/>
  </>
}