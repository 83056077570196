import React, {FC, FormEvent, useCallback, useEffect, useMemo, useState} from "react";
import {ContentContainer} from "../components/content/ContentContainer";
import {PageIconHeader} from "../components/layout/PageIconHeader";
import {Breadcrumbs} from "../components/content/Breadcrumbs";
import {PageHeader} from "../components/content/PageHeader";
import {faAdd, faInbox, faTrash} from "@fortawesome/free-solid-svg-icons";
import {useModal} from "../components/layout/ModalProvider";
import {Button, IconButton} from "../components/form/Button";
import {ErrorBag, useApiCall, ValidationError} from "../api/api";
import {Customer, Team, TicketPriority, TicketTemplate} from "../api/dto";
import {useFetchedResource} from "../api/APIContext";
import {useRefresh, useRefreshEffect} from "../components/RefreshController";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTenant} from "../tenant/TenantContext";
import {useNavigate, useParams} from "react-router-dom";
import {DeleteTicketTemplateModal} from "../modals/DeleteTicketTemplateModal";
import {Card, CardDivider} from "../components/Card";
import {Input} from "../components/form/Input";
import {InputErrors} from "../components/form/InputErrors";
import {Select} from "../components/form/Select";
import {Autocomplete} from "../components/form/Autocomplete";
import {Toggle} from "../components/form/Toggle";
import {Paragraph} from "../components/Paragraph";
import {faCheckCircle, faCalendar, faClock} from "@fortawesome/free-regular-svg-icons";
import {parseExpression} from 'cron-parser'
import moment from "moment";

export const TicketTemplateDetails: FC = () => {
  const {tenant} = useTenant();
  const {template} = useParams()
  const {getTicketTemplate, getAllPriorities, getCustomers, getTeams} = useApiCall()
  const {resource: ticketTemplate, reload: reloadTicketTemplate} = useFetchedResource((id: string) => getTicketTemplate(id))
  const {resource: ticketPriorities, reload: reloadPriorities} = useFetchedResource(() => getAllPriorities())
  const {resource: customers, reload: reloadCustomers} = useFetchedResource(() => getCustomers())
  const {resource: teams, reload: reloadTeams} = useFetchedResource(() => getTeams())
  const navigate = useNavigate()
  useRefreshEffect(() => {
    if (template === undefined) {
      console.warn('ticket uuid is undefined')
      return
    }
    reloadTicketTemplate(template)
  }, [template])


  useRefreshEffect(() => {
    reloadPriorities(undefined)
    reloadCustomers(undefined)
    reloadTeams(undefined)
    reloadTeams(undefined)
  })

  const {open: openDeleteModal} = useModal({title: 'Template toevoegen', body: <DeleteTicketTemplateModal template={ticketTemplate!} onDeleted={() => navigate(`/${tenant}/ticket-templates`)} />, size: 'md'})

  return (
    <ContentContainer size={'xl'}>
      <div className={"flex justify-between items-end"}>
        <PageIconHeader icon={faInbox}>
          <Breadcrumbs crumbs={[
            {label: "Servicedesk", href: `/${tenant}/ticket`},
            {label: "Ticket Templates", href: `/${tenant}/ticket-templates`},
          ]} currentPage={"Template: " + (ticketTemplate?.name ?? '...')} />
          <PageHeader>{"Template: " + (ticketTemplate?.name ?? '...')}</PageHeader>
        </PageIconHeader>
        <div className={"flex space-x-4 mb-8"}>
          <Button size={"md"} disabled={!ticketTemplate} type={"danger"} text={"Template verwijderen"} icon={faTrash} onClick={() => openDeleteModal()} />
        </div>
      </div>
      {ticketTemplate && ticketPriorities && customers && teams && <TicketTemplateDetailsForm customers={customers} teams={teams} priorities={ticketPriorities} ticketTemplate={ticketTemplate} />}
      {ticketTemplate && <TicketTemplateScheduleForm ticketTemplate={ticketTemplate} />}
      {ticketTemplate && <TicketTemplateTasksForm ticketTemplate={ticketTemplate} />}

    </ContentContainer>
  );
}

const TicketTemplateDetailsForm: FC<{customers: Customer[], teams: Team[], priorities: TicketPriority[], ticketTemplate: TicketTemplate}> = props => {
  const {updateTicketTemplate} = useApiCall()
  const refactoredPriorities = props.priorities.map((item) => [item.id, item.priority])
  const refactoredCustomers = [
    ['-', 'Geen'],
    ...props.customers.map((item) => [item.id, item.name])
  ]
  const refactoredTeams = [
    ['-', 'Geen'],
    ...props.teams.map((item) => [item.id, item.team_name])
  ]
  const [name, setName] = useState<string>(props.ticketTemplate.name)
  const [title, setTitle] = useState<string>(props.ticketTemplate.subject)
  const [description, setDescription] = useState<string>(props.ticketTemplate.description)
  const [priority, setPriority] = useState(props.ticketTemplate.priorityId)
  const [customer, setCustomer] = useState(props.ticketTemplate.customerId ?? '-')
  const [type, setType] = useState<'incident'|'service_request'|'change'|'problem'>(props.ticketTemplate.type as 'incident'|'service_request'|'change'|'problem') // not being used for now
  const [team, setTeam] = useState(props.ticketTemplate.teamId ?? '-') // not being used for now
  const [errors, setErrors] = useState<ErrorBag>({})

  const reload = useRefresh()

  useEffect(() => {
    setName(props.ticketTemplate.name)
    setTitle(props.ticketTemplate.subject)
    setDescription(props.ticketTemplate.description)
    setPriority(props.ticketTemplate.priorityId)
    setCustomer(props.ticketTemplate.customerId ?? '-')
    setType(props.ticketTemplate.type as 'incident'|'service_request'|'change'|'problem')
    setTeam(props.ticketTemplate.teamId ?? '-')
  }, [props.ticketTemplate]);

  const isDirty = name !== props.ticketTemplate.name || title !== props.ticketTemplate.subject || description !== props.ticketTemplate.description || priority !== props.ticketTemplate.priorityId || customer !== (props.ticketTemplate.customerId ?? '-') || team !== (props.ticketTemplate.teamId ?? '-') || type !== props.ticketTemplate.type

  const save = useCallback( async () => {
    const promise = updateTicketTemplate(props.ticketTemplate.id, {
      name: name,
      subject: title,
      description: description,
      priority_uuid: priority,
      customer_uuid: customer === '-' ? null : customer,
      team_uuid: team === '-' ? null : team,
    }).catch(error => {
      if (error instanceof ValidationError) {
        setErrors(error.errors)
        throw new Error('Validation error')
      }
      throw error
    })
    await promise
    reload()
  }, [name, title, description, priority, customer, type, team])
  return <Card title={'Informatie'}>
    <div className={""}>
      <Input label={"Naam"} type={"text"} value={name} onChange={(v) => setName(v)}/>
      <InputErrors errors={errors} field={'name'}/>
    </div>
    <div className={'my-4'}>
      <Input label={"Beschrijving"} type={"textarea"} value={description} onChange={(v) => setDescription(v)}/>
      <InputErrors errors={errors} field={'description'}/>
    </div>
    <CardDivider />
    <div className={'text-sm font-medium text-slate-900 dark:text-zinc-300 mb-1'}>Type</div>
    <div className={'grid grid-cols-1 lg:grid-cols-2 gap-3 w-full mb-4'}>
      <button type={'button'} onClick={() => setType('change')}
              className={`rounded px-4 py-3 flex flex-col items-start text-left ${type === 'change' ? 'text-brand-900 dark:text-brand-100 border border-brand-700 dark:border-brand-700 bg-brand-50 dark:bg-brand-900' : 'text-slate-600 dark:text-zinc-300 border border-slate-200 dark:border-zinc-600'}`}>
        <h2 className={'text-black dark:text-white'}>Change</h2>
        <p className={'mt-1 text-xs'}>Wijziging in infrastructuur of diensten</p>
      </button>
      <button type={'button'} onClick={() => setType('service_request')}
              className={`rounded px-4 py-3 flex flex-col items-start text-left ${type === 'service_request' ? 'text-brand-900 dark:text-brand-100 border border-brand-700 dark:border-brand-700 bg-brand-50 dark:bg-brand-900' : 'text-slate-600 dark:text-zinc-300 border border-slate-200 dark:border-zinc-600'}`}>
        <h2 className={'text-black dark:text-white'}>Service Request</h2>
        <p className={'mt-1 text-xs'}>Verzoek voor standaarddienst of toegestane informatie</p>
      </button>
    </div>
    <div className={"items-center"}>
      <Input label={"Titel"} type={"text"} value={title} onChange={(v) => setTitle(v)}/>
      <InputErrors errors={errors} field={'subject'}/>
    </div>
    <div className={"grid grid-cols-3 gap-4 my-4"}>
      <Select label={"Prioriteit"} options={Object.fromEntries(refactoredPriorities)} value={priority}
              onChange={(v) => setPriority(v)}/>
      <Autocomplete label={"Klant"} options={Object.fromEntries(refactoredCustomers)} value={customer}
                    onChange={(v) => setCustomer(v)}/>
      <Autocomplete label={"Team"} options={Object.fromEntries(refactoredTeams)} value={team}
                    onChange={(v) => setTeam(v)}/>
    </div>

    <Button type={'primary'} size={'md'} text={'Opslaan'} onClick={() => save()} disabled={!isDirty} />
  </Card>
}

const TicketTemplateScheduleForm: FC<{ticketTemplate: TicketTemplate}> = props => {
  const [schedule, setSchedule] = useState(props.ticketTemplate.schedule)
  const [errors, setErrors] = useState<ErrorBag>({})
  const {updateTicketTemplate} = useApiCall()
  const reload = useRefresh()

  useEffect(() => {
    setSchedule(props.ticketTemplate.schedule)
  }, [props.ticketTemplate]);
  const save = useCallback( async () => {
    setErrors({})
    await updateTicketTemplate(props.ticketTemplate.id, {
      schedule: schedule,
    }).then(() => {
      reload()
    }).catch(error => {
      if (error instanceof ValidationError) {
        setErrors(error.errors)
      }
    })
  }, [schedule])

  const options = [
    {
      value: '0 6 * * 1-5',
      label: 'Dagelijks',
      description: 'Dagelijks om 6:00',
    },
    {
      value: '0 6 * * 1',
      label: 'Wekelijks',
      description: 'Elke maandag om 6:00',
    },
    {
      value: '0 6 1,15 * *',
      label: '2x per maand',
      description: 'OP de 1e en de 15e van de maand om 6:00',
    },
    {
      value: '0 6 1 * *',
      label: 'Maandelijks',
      description: 'Elke 1e van de maand om 6:00',
    },
    {
      value: '0 6 1 1,4,7,10 *',
      label: '1x per kwartaal',
      description: 'Elke 1e dag van het kwartaal om 6:00',
    },
    {
      value: '0 6 1 1,7 *',
      label: '1x per half jaar',
      description: 'Elke 1e dag van januari en juli om 6:00',
    },
    {
      value: '0 6 1 1 *',
      label: '1x per jaar',
      description: 'Elke 1e dag van januari om 6:00',
    },
  ]
  const optionValues = options.map(o => o.value)

  const cronPreview = useMemo(() => {
    if (schedule === null || schedule === '') {
      return []
    }
    try {
      const expression = parseExpression(schedule)
      return [expression.next().toDate(), expression.next().toDate(), expression.next().toDate()]
    } catch (e) {
      return []
    }
  }, [schedule])

  return <Card title={'Automatisch aanmaken'}>
    <Toggle label={'Automatisch tickets aanmaken'} value={schedule !== null} onChange={(value) => {
      if (value) {
        setSchedule(options[0].value)
      } else {
        setSchedule(null)
      }
    }} />
    {schedule !== null && <>
      <CardDivider/>
      <div className={'grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-3 w-full mb-4'}>
        {options.map((o, i) => {
          return <button type={'button'} key={i} onClick={() => setSchedule(o.value)}
                         className={`rounded px-4 py-3 flex flex-col items-start text-left ${schedule === o.value ? 'text-brand-900 dark:text-brand-100 border border-brand-700 dark:border-brand-700 bg-brand-50 dark:bg-brand-900' : 'text-slate-600 dark:text-zinc-300 border border-slate-200 dark:border-zinc-600'}`}>
            <h2 className={'text-black dark:text-white'}>{o.label}</h2>
            <p className={'mt-1 text-xs'}>{o.description}</p>
          </button>
        })}
        <button type={'button'} onClick={() => setSchedule('')}
                className={`rounded px-4 py-3 flex flex-col items-start text-left ${!optionValues.includes(schedule) ? 'text-brand-900 dark:text-brand-100 border border-brand-700 dark:border-brand-700 bg-brand-50 dark:bg-brand-900' : 'text-slate-600 dark:text-zinc-300 border border-slate-200 dark:border-zinc-600'}`}>
          <h2 className={'text-black dark:text-white'}>Aangepast</h2>
          <p className={'mt-1 text-xs'}>Gebruik CRON notiatie</p>
        </button>
      </div>
      <div className={'grid grid-cols-1 lg:grid-cols-2 gap-3'}>
        <div>
          <Paragraph>
            Meer informatie over CRON notatie is te vinden op <a href={'https://crontab.guru/'} target={'_blank'} rel={'noreferrer'} className={'font-medium underline text-brand-700 dark:text-brand-300'}>crontab.guru</a> en <a href={'https://en.wikipedia.org/wiki/Cron'} target={'_blank'} rel={'noreferrer'} className={'font-medium underline text-brand-700 dark:text-brand-300'}>wikipedia.org</a>.
          </Paragraph>
          {cronPreview.length > 0 && <div className={'mt-4'}>
            <h3 className={'text-sm font-medium text-slate-900 dark:text-zinc-300'}>Volgende uitvoeringen</h3>
            <ul className={'list-inside text-sm pl-2 mb-4'}>
              {cronPreview.map((item, i) => {
                return <li key={i} className={'flex items-center leading-relaxed opacity-90'}>
                  <FontAwesomeIcon icon={faCalendar} className={''} />
                  <span className={'ml-2'}>{moment(item).format('YYYY-MM-DD')}</span>
                  <FontAwesomeIcon icon={faClock} className={'ml-4'} />
                  <span className={'ml-1'}>{moment(item).format('HH:mm')}</span>
                </li>
              })}
            </ul>
          </div>}
          <div className={"items-center"}>
            <Input label={"Aangepast schema"} type={"text"} value={schedule ?? ''} onChange={(v) => setSchedule(v)}/>
            <InputErrors errors={errors} field={'schedule'}/>
          </div>
        </div>
      </div>
    </>}
    <CardDivider/>
    <Button type={'primary'} size={'md'} text={'Opslaan'} onClick={() => save()}
            disabled={schedule === props.ticketTemplate.schedule}/>
  </Card>
}

const TicketTemplateTasksForm: FC<{ticketTemplate: TicketTemplate}> = props => {
  const [tasks, setTasks] = useState(props.ticketTemplate.tasks)
  const [addTask, setAddTask] = useState('')
  const [, setErrors] = useState<ErrorBag>({})
  const {updateTicketTemplate} = useApiCall()
  const reload = useRefresh()

  useEffect(() => {
    setTasks(props.ticketTemplate.tasks)
  }, [props.ticketTemplate]);
  const save = useCallback( async () => {
    await updateTicketTemplate(props.ticketTemplate.id, {
      tasks: tasks,
    }).then(() => {
      reload()
    }).catch(error => {
      if (error instanceof ValidationError) {
        setErrors(error.errors)
      }
    })
  }, [tasks])

  const onAddTask = (event: FormEvent) => {
    event.preventDefault()
    setTasks([...tasks, addTask])
    setAddTask('')
  }

  return <Card title={'Taken'}>
    <div>
      {tasks.map((task, i) => {
        return <div key={i} className={'flex items-center h-12 border border-slate-200 dark:border-zinc-600 rounded mb-3 pl-3 pr-2'}>
          <FontAwesomeIcon icon={faCheckCircle} className={'mr-3'}/>
          <div className={'flex-1'}>{task}</div>
          <IconButton icon={faTrash} size={'sm'} type={'danger'}
                      onClick={() => setTasks(tasks.filter((t, j) => i !== j))}/>
        </div>
      })}
    </div>
    <form className={'flex items-end'} onSubmit={onAddTask}>
      <div className={'flex-1 mr-3'}>
        <Input type={'text'} label={'Taak'} value={addTask} onChange={setAddTask} placeholder={'Taak toevoegen...'}/>
      </div>
      <Button submit={true} type={'primary'} size={'md'} text={'Toevoegen'} icon={faAdd}/>
    </form>
    <CardDivider/>
    <Button type={'primary'} size={'md'} text={'Opslaan'} onClick={() => save()}
            disabled={tasks.join(',') === props.ticketTemplate.tasks.join(',')}/>
  </Card>
}