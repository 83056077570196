import React, {FC} from "react";

interface SelectProps {
  label: string
  options: {[key: string]: string}
  value: string
  onChange: (newValue: string) => void
  inline?: boolean
}

export const Select: FC<SelectProps> = (props) =>  {
  return <label className={`flex ${props.inline ? 'flex-row whitespace-nowrap items-center' : 'flex-col'} text-sm font-medium text-slate-900 dark:text-zinc-300 w-full`}>
      <span className={props.inline ? 'mr-2' : ''}>{props.label}</span>
    <select
      className={`border-2 border-slate-200 dark:border-zinc-500 outline-brand-700 dark:outline-brand-600 text-black dark:text-zinc-300 dark:bg-zinc-600 rounded text-base font-normal px-2 ${props.inline ? '' : 'mt-1'} h-10 w-full`}
      onChange={(e) => props.onChange(e.target.value)}
      value={props.value}
    >
      {Object.entries(props.options).map(([key, option]) => {
        return <option value={key} key={key}>{option}</option>
      })}
    </select>
  </label>
}