import {FC, useCallback, useState} from "react";
import {Asset, AssetType, Customer, User} from "../api/dto";
import {Input} from "../components/form/Input";
import {InputErrors} from "../components/form/InputErrors";
import {Autocomplete} from "../components/form/Autocomplete";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleMinus} from "@fortawesome/pro-regular-svg-icons";
import {ModalFooter} from "../components/layout/ModalProvider";
import {faSave} from "@fortawesome/free-solid-svg-icons";
import {ErrorBag, useApiCall, ValidationError} from "../api/api";
import {useRefresh} from "../components/RefreshController";

interface CustomFields {
  key: string
  value: string
}
export const EditAssetModal: FC<{ asset: Asset, customers: Customer[], assetTypes: AssetType[], users: User[] }> = (props) => {
  const {editAsset} = useApiCall()
  const [name, setName] = useState<string>(props.asset.name)
  const [purchaseDate, setPurchaseDate] = useState(props.asset.purchased_at ?? new Date())
  const [deliveryDate, setDeliveryDate] = useState(props.asset.delivered_at ?? new Date())
  const [price, setPrice] = useState(props.asset.price ? props.asset.price.toString() : '')
  const [model, setModel] = useState(props.asset.model ?? '')
  const [brand, setBrand] = useState(props.asset.brand ?? '')
  const [type, setType] = useState(props.asset.asset_type_id ?? '')
  const [serialNumber, setSerialNumber] = useState(props.asset.serial_number ?? '')
  const [errors, setErrors] = useState<ErrorBag>({})
  const [customFields, setCustomFields] = useState<CustomFields[]>([])
  const reload = useRefresh()
  const save = useCallback(async () => {
    await editAsset(props.asset.id,
      {
      name: name,
      purchased_at: purchaseDate,
      delivered_at: deliveryDate,
      price: price,
      model: model,
      brand: brand,
      serial_number: serialNumber,
    }).catch(error => {
      if (error instanceof ValidationError) {
        setErrors(error.errors)
        throw new Error('Validation error')
      }
      throw error
    })
    reload();
  }, [name, purchaseDate, deliveryDate, price, model, brand, serialNumber])

  return <>
    <form className={"space-y-4"} onSubmit={save}>

      <div className={""}>
        <Input type={'text'} label={'naam'} value={name} onChange={(x) => setName(x)} required={true} />
        <InputErrors errors={errors} field={'name'} />
      </div>

      {props.assetTypes.find(t => t.id === type)?.isPhysicalProduct ? <>
        <div className={'grid grid-cols-3 gap-4'}>
          <div className={"col-span-1"}>
            <Input type={'text'} label={'Merk'} value={brand} onChange={(x) => setBrand(x)} />
            <InputErrors errors={errors} field={'brand'} />
          </div>
          <div className={"col-span-1"}>
            <Input type={'text'} label={'Model'} value={model} onChange={(x) => setModel(x)} />
            <InputErrors errors={errors} field={'model'} />
          </div>
          <div className={"col-span-1"}>
            <Input type={'text'} label={'Serie nummer'} value={serialNumber} onChange={(x) => setSerialNumber(x)} />
            <InputErrors errors={errors} field={'serial_number'} />
          </div>
        </div>
      </> : <> </>}

      <div className={'grid grid-cols-3 gap-4'}>
        <div className={"col-span-1"}>
          <Input type={'text'} label={'Prijs'} value={price} onChange={(x) => setPrice(x)} />
          <InputErrors errors={errors} field={'price'} />
        </div>
        <div className={"col-span-1"}>
          <Input type={'date'} label={'Aankoopddatum'} value={purchaseDate} onChange={setPurchaseDate} />
          <InputErrors errors={errors} field={'purchased_at'} />
        </div>
        <div className={"col-span-1"}>
          <Input type={'date'} label={'Leverdatum'} value={deliveryDate} onChange={setDeliveryDate} />
          <InputErrors errors={errors} field={'delivered_at'} />
        </div>
      </div>

      {/* Optional fields */}
      {/*<div>*/}
      {/*  {customFields.length > 0 && <>*/}
      {/*    <h1 className={'mt-4 mb-2'}>Extra velden</h1>*/}
      {/*    {customFields.map((fields, i) => {*/}
      {/*      return <div className={'flex flex-row items-center content-center space-x-3 mb-3'}>*/}
      {/*        <div className={"flex-1"}>*/}
      {/*          <Input type={'text'} label={''} value={fields.key ?? ''} onChange={(x) => setFieldState(i, 'key', x)} />*/}
      {/*        </div>*/}
      {/*        <div className={"flex-1"}>*/}
      {/*          <Input type={'text'} label={''} value={fields.value ?? ''} onChange={(x) => setFieldState(i, 'value', x)} />*/}
      {/*        </div>*/}
      {/*        <FontAwesomeIcon icon={faCircleMinus} className={'text-red-600 max-w-8 px-3 py-3 hover:bg-red-300 hover:dark:bg-zinc-600 hover:cursor-pointer rounded'}*/}
      {/*                         onClick={() => removeItem(i)}/>*/}
      {/*      </div>*/}
      {/*    })}*/}
      {/*  </>}*/}

        {/* Add additional fields */}
        {/*TODO: uncomment when decision has been made over custom field templates.*/}
        {/*<div className={'flex space-x-3 items-center justify-center text-brand-300 dark:text-brand-500 hover:text-brand-400 hover:dark:text-brand-400 hover:bg-slate-300 hover:dark:bg-zinc-600 rounded hover:cursor-pointer py-2 px-3'}*/}
        {/*      onClick={addNewItem}>*/}
        {/*  <span>Extra veld</span>*/}
        {/*  <FontAwesomeIcon icon={faCirclePlus} />*/}
        {/*</div>*/}

      {/*</div>*/}
    </form>
    <ModalFooter text={'Opslaan'} icon={faSave} onSubmit={save} />
  </>
}