import React, {FC, useState} from "react";
import {Contract, Customer, Ticket, TicketTime, TicketTimeCategory} from "../../../api/dto";
import {
  faAdd,
  faChevronDown,
  faChevronUp,
  faCircleNotch,
  faPencil,
  faTrash,
  faTriangleExclamation
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClock} from "@fortawesome/free-regular-svg-icons";
import {Button, IconButton} from "../../form/Button";
import {useModal} from "../../layout/ModalProvider";
import {faArrowUpArrowDown, faStopwatch} from "@fortawesome/pro-regular-svg-icons";
import {usePersistentState} from "../../../util/usePersistentState";
import {IconDropdownButton} from "../../form/IconButtonDropdown";
import {DeleteTicketTimeModal} from "../../../modals/DeleteTicketTimeModal";
import {EditTicketTimeModal} from "../../../modals/EditTicketTimeModal";
import {NavLink} from "react-router-dom";
import {useTenant} from "../../../tenant/TenantContext";
import {Input} from "../../form/Input";
import {Autocomplete, FancyAutocomplete} from "../../form/Autocomplete";
import {useFetchedResource} from "../../../api/APIContext";
import {ErrorBag, useApiCall, ValidationError} from "../../../api/api";
import {useRefresh, useRefreshEffect} from "../../RefreshController";
import {faDollarCircle, faDollar, faFile} from "@fortawesome/pro-light-svg-icons";
import {InputErrors} from "../../form/InputErrors";
import {formatTimeString} from "../../../util/formatTimeString";
import moment from "moment";
import {useLocalSettings} from "../../LocalSettingsContext";
import {faPlay} from "@fortawesome/pro-solid-svg-icons";
import {useTimeContext} from "../../time/TimeContext";

export const TicketTimeTab: FC<{ ticket: Ticket, customer?: Customer }> = (props) => {
  const tenant = useTenant()
  const [order, setOrder] = usePersistentState<'ASC'|'DESC'>('order', 'DESC');
  const sortedTimes = props.ticket.times
    .sort((a, b) => new Date(b.startedAt).getTime() - new Date(a.startedAt).getTime());
  const orderedTimes = order === 'ASC' ? [...sortedTimes].reverse() : sortedTimes;
  const groupedTimes = orderedTimes.reduce((acc: Record<string, typeof props.ticket.times>, time) => {
    const dateKey = new Date(time.startedAt).toISOString().split('T')[0];
    if (!acc[dateKey]) {
      acc[dateKey] = [];
    }
    acc[dateKey].push(time);
    return acc;
  }, {});
  const {getTicketTimeCategories, getContracts} = useApiCall()
  const ticketTimeCategories = useFetchedResource(() => getTicketTimeCategories())
  const contracts = useFetchedResource(() => getContracts())
  useRefreshEffect(() => {
    ticketTimeCategories.reload(undefined)
    contracts.reload(undefined)
  })

  if (props.ticket.times.length === 0) {
    return <div className="border border-slate-200 rounded dark:border-zinc-500 bg-white dark:bg-zinc-700 space-x-2 flex flex-col items-center py-8">
      <FontAwesomeIcon icon={faClock} className={'text-3xl text-slate-600 dark:text-zinc-400'}/>
      <div className={'my-6 text-slate-800 dark:text-zinc-200'}>
        Er zijn nog geen tijden geschreven op dit ticket<br />
        <ol className={'list-decimal pl-6 mt-3 mb-3'}>
          <li>Zet de datum goed.</li>
          <li>Kies een post, dit kan een <NavLink to={`/${tenant?.tenant}/ticketflow`} className={'underline'}>categorie</NavLink> of klant-contract zijn.</li>
          <li>Vul je uren in, dit wordt afgerond op 5 minuten.</li>
          <li>Omschrijf je werkzaamheden.</li>
        </ol>
      </div>
      <div className={'w-full px-6'}>
        {ticketTimeCategories.resource && props.customer && contracts.resource && <InlineHoursAddForm ticket={props.ticket} categories={ticketTimeCategories.resource} contracts={contracts.resource} customer={props.customer} />}
      </div>
    </div>
  }

  return <div>
    <div className={"flex justify-end"}>
      <IconDropdownButton icon={faArrowUpArrowDown} type={'ticket-table'} size={'sm'}
                          text={order === "DESC" ? 'Aflopend' : 'Oplopend'} data={{'ASC': 'Oplopend', 'DESC': 'Aflopend'}} onClick={() => setOrder(prevOrder => prevOrder === 'ASC' ? 'DESC' : 'ASC')}  />
    </div>
    {order === "DESC" && ticketTimeCategories.resource && props.customer && contracts.resource && <TicketTimeAdd ticket={props.ticket} orderBy={order} categories={ticketTimeCategories.resource} contracts={contracts.resource} customer={props.customer}/>}
    {props.ticket.times.length > 0 ? (
      Object.entries(groupedTimes).map(([date, times], index) => (
          <div className={"mt-4"} key={date}>
            <h2 className="font-semibold text-lg mb-1">
              {new Date(date).toLocaleDateString("nl-NL", {
                weekday: "long",
                day: "numeric",
                month: "long",
              })}{" "}
              ({formatTimeString(times.reduce((total, time) => total + time.value, 0))})
            </h2>
            {times.map((time, i) => (
              <TicketTimeRow key={time.id || i} ticket={props.ticket} categories={ticketTimeCategories.resource ?? []}
                             ticketTime={time} contracts={contracts.resource ?? []} isFirst={i == 0}
                             isLast={index === Object.entries(groupedTimes).length - 1 || i === times.length - 1}/>
            ))}
          </div>
        ))
    ) : (
      <div className="flex border border-slate-200 dark:border-zinc-500 bg-white dark:bg-zinc-700 space-x-2">
        <FontAwesomeIcon icon={faClock}/>
        <h1>Er zijn nog geen tijden geschreven op dit ticket</h1>
      </div>
    )}
    {order === "ASC" && ticketTimeCategories.resource && contracts.resource && props.customer && <TicketTimeAdd ticket={props.ticket} orderBy={order} categories={ticketTimeCategories.resource} contracts={contracts.resource} customer={props.customer} />}
  </div>
};

const TicketTimeAdd: FC<{ ticket: Ticket, orderBy: string, categories: TicketTimeCategory[], contracts: Contract[], customer: Customer }> = (props) => {
  return <div className={`flex flex-col items-center rounded border border-slate-200 dark:border-zinc-500 bg-white dark:bg-zinc-700 space-y-2 p-4 my-4`}>
    {props.ticket.status?.isClosed ?
      <div>
        <h1>Tijden kunnen niet toegevoegd of veranderd worden met deze status</h1>
      </div>
      :
      <InlineHoursAddForm ticket={props.ticket} categories={props.categories} contracts={props.contracts} customer={props.customer} />
    }
  </div>
}

const TicketTimeRow: FC<{ ticket: Ticket, ticketTime: TicketTime, categories: TicketTimeCategory[], contracts: Contract[], isFirst: boolean, isLast: boolean }> = (props) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false)
  const {open: deleteModal} = useModal({title: "Tijd verwijderen", body: <DeleteTicketTimeModal ticket={props.ticket} time={props.ticketTime} />, size: "md"})
  const {open: editModal} = useModal({title: "Tijd Bewerken", body: <EditTicketTimeModal ticket={props.ticket} time={props.ticketTime} categories={props.categories} contracts={props.contracts} />, size: "md"})
  const category = props.ticketTime.category_id ? props.categories.find(c => c.id === props.ticketTime.category_id) : undefined
  const contract = props.ticketTime.contract_id ? props.contracts.find(c => c.id === props.ticketTime.contract_id) : undefined
  const {enrolledInTimeBeta} = useLocalSettings()
  const {startCategoryTimer, startContractTimer} = useTimeContext()

  const startTimer = async() => {
    if (category) {
      startCategoryTimer(props.ticket.id, props.ticket.subject ?? '?', new Date(), props.ticketTime.description, category.id, category.name, category.isBillable)
    }
    if (contract) {
      startContractTimer(props.ticket.id, props.ticket.subject ?? '?', new Date(), props.ticketTime.description, contract.id, contract.name, contract.timeCategory.isBillable)
    }
  }
  return <div
    className={`p-3 ${props.isFirst ? 'rounded-t border-t' : 'rounded-t-0'} ${props.isLast ? 'rounded-b' : ''} border-b border-l border-r border-slate-200 dark:border-zinc-500 bg-white dark:bg-zinc-700`}>
    <div className={"flex items-center"}>
      <div className={"w-64"}>
        <span className="py-1 px-1">{props.ticketTime.user_name}</span>
      </div>
      <div className={"flex-1"}>
        {category && <><FontAwesomeIcon icon={category.isBillable ? faDollarCircle : faDollar}
                                        className={`mr-2 w-4 ${category.isBillable ? 'text-brand-500 dark:text-brand-500' : 'text-slate-600 dark:text-zinc-400'}`}/> {category?.name ?? '-'}</>}
        {contract && <><FontAwesomeIcon icon={faFile}
                                        className={`mr-2 w-4 ${contract.timeCategory.isBillable ? 'text-brand-500 dark:text-brand-500' : 'text-slate-600 dark:text-zinc-400'}`}/> {contract?.name ?? '-'} </>}
      </div>
      <div className={"w-32"}>
        <span className="py-1 px-1">{formatTimeString(props.ticketTime.value)}</span>
      </div>
      {enrolledInTimeBeta && <div
        className={"border rounded border-slate-200 mr-3 dark:border-zinc-500 hover:dark:bg-zinc-600 px-2 py-1 hover:cursor-pointer"}
        onClick={() => startTimer()}>
        <FontAwesomeIcon icon={faStopwatch}/>
      </div>}
      <div
        className={"border rounded border-slate-200 dark:border-zinc-500 hover:dark:bg-zinc-600 px-2 py-1 hover:cursor-pointer"}
        onClick={() => setIsCollapsed(!isCollapsed)}>
        <FontAwesomeIcon icon={isCollapsed ? faChevronUp : faChevronDown}/>
      </div>
    </div>
    {isCollapsed &&
      <div>
        <div
          className={"flex pl-3 py-2 mt-3 rounded border border-slate-200 dark:border-zinc-600 text-sm text-slate-800 dark:text-zinc-200"}>
          {props.ticketTime.description}
        </div>
        <div className={"flex space-x-2 mt-2"}>
          <Button type={'primary'} size={'sm'} text={"Bewerk"} icon={faPencil} onClick={editModal}/>
          <Button type={'danger'} size={'sm'} text={"Verwijder"} icon={faTrash} onClick={deleteModal} />
        </div>
      </div>
    }
  </div>
}

const InlineHoursAddForm: FC<{ticket: Ticket, categories: TicketTimeCategory[], contracts: Contract[], customer: Customer}> = (props) => {
  const [date, setDate] = useState(new Date())
  const [time, setTime] = useState<number>(60)
  const [category, setCategory] = useState('')
  const [description, setDescription] = useState('')
  const [errors, setErrors] = useState<ErrorBag>({})

  const options = [
    ...props.contracts
      .filter(contract => contract.customerId === props.customer.id)
      .filter(contract => contract.status === 'Actief')
      .map(contract => ({
        key: contract.id,
        text: contract.name,
        subText: moment(contract.startAt).format('Do MMM') + ' - ' + moment(contract.endAt).format('Do MMM'),
        leading: <FontAwesomeIcon icon={faFile} className={`text-xs ${contract.timeCategory.isBillable ? 'text-brand-500 dark:text-brand-500' : 'text-slate-600 dark:text-zinc-400'}`}/>
      }))
      .sort((a, b) => a.text.localeCompare(b.text)), // Sort by contract name
    ...props.categories.map(c => ({
      key: c.id,
      text: c.name,
      subText: c.code,
      leading: <FontAwesomeIcon icon={c.isBillable ? faDollarCircle : faDollar}
                                className={`text-xs ${c.isBillable ? 'text-brand-500 dark:text-brand-500' : 'text-slate-600 dark:text-zinc-400'}`}/>
    }))
      .sort((a, b) => a.subText.localeCompare(b.subText)) // Sort by category code
  ];
  const {addTicketTimeWithCategory, addTicketTimeWithContract} = useApiCall()
  const [loading, setLoading] = useState(false)
  const reload = useRefresh()
  const {enrolledInTimeBeta} = useLocalSettings()
  const {startCategoryTimer, startContractTimer, runningTimer} = useTimeContext()
  const resetForm = () => {
    setDate(new Date())
    setTime(60)
    setCategory('')
    setDescription('')
    setErrors({})
  }
  const save = async(e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setLoading(true)
    if (props.categories.some((m) => m.id === category)) {
      await addTicketTimeWithCategory(props.ticket.id, date, time, description, category).catch(error => {
        if (error instanceof ValidationError) {
          setErrors(error.errors)
          throw new Error('Validation error')
        }
        throw error
      }).finally(() => setLoading(false))
      reload()
      setLoading(false)
    } else {
      await addTicketTimeWithContract(props.ticket.id, date, time, description, category).catch(error => {
        if (error instanceof ValidationError) {
          setErrors(error.errors)
          throw new Error('Validation error')
        }
        throw error
      }).finally(() => setLoading(false))
      reload()
      setLoading(false)
    }
  }
  const startTimer = async() => {
    const cat = props.categories.find(c => c.id === category)
    if (cat) {
      startCategoryTimer(props.ticket.id, props.ticket.subject ?? '?', date, description, category, cat.name, cat.isBillable)
      resetForm()
    }
    const contract = props.contracts.find(c => c.id === category)
    if (contract) {
      startContractTimer(props.ticket.id, props.ticket.subject ?? '?', date, description, contract.id, contract.name, contract.timeCategory.isBillable)
    }
  }
  if (enrolledInTimeBeta) {
    return <form className={"grid grid-cols-4 gap-x-4 gap-y-3 w-full"} onSubmit={save}>
      <div className={""}>
        <InputErrors errors={errors} field={'created_at'}/>
        <Input type={'date'} label={'Datum'} value={date} onChange={setDate}/>
      </div>
      <div className={""}>
        <InputErrors errors={errors} field={'created_at'}/>
        <Input type={'time'} label={'Start tijd'} value={date.getHours() * 60 + date.getMinutes()}
               onChange={(minutes) => setDate(oldDate => {
                 const newDate = new Date(oldDate)
                 newDate.setHours(Math.floor(minutes / 60), minutes % 60)
                 return newDate
               })}/>
      </div>
      <div className={""}>
        <InputErrors errors={errors} field={'created_at'}/>
        <Input type={'time'} label={'Eind tijd'} value={date.getHours() * 60 + date.getMinutes() + time}
               onChange={(minutes) => {
                 const start = date.getHours() * 60 + date.getMinutes()
                 let diff = minutes - start
                 if (diff < 0) {
                   diff += 60 * 24
                 }
                 setTime(diff)
               }}/>
      </div>
      <div className={""}>
        <InputErrors errors={errors} field={'value'}/>
        <Input type={'time'} label={'Duur'} value={time} onChange={setTime}/>
      </div>
      <div className={""}>
        <InputErrors errors={errors} field={'category'}/>
        <FancyAutocomplete label={'Post'} value={category} onChange={(selection) => setCategory(selection)}
                           options={options}/>
      </div>
      <div className={"col-span-2"}>
        <InputErrors errors={errors} field={'description'}/>
        <Input type={'text'} label={'Omschrijving'} value={description} onChange={setDescription}/>
      </div>
      {!runningTimer ? <>
        <div className={'flex'}>
          <div className={"flex flex-col items-stretch justify-end mr-3"}>
            <IconButton type={'primary'} size={'md'} icon={faPlay} onClick={startTimer} disabled={category === ''}/>
          </div>
          <div className={"flex-1 flex flex-col items-stretch justify-end"}>
            {!loading ? <Button type={'primary'} submit size={'md'} text={"Toevoegen"} disabled={category === ''}/> :
              <div className={'text-slate-600 dark:text-zinc-400 flex items-center justify-center h-10'}>
                <FontAwesomeIcon
                  spin={true} icon={faCircleNotch}/></div>}
          </div>
        </div>
      </> : <div className={"flex flex-col items-stretch justify-end"}>
        <div className="flex items-center justify-center h-10 space-x-2 border border-slate-200 dark:border-zinc-500 text-slate-600 dark:text-zinc-400 text-sm rounded">
          <FontAwesomeIcon icon={faTriangleExclamation}/>
          <h1>Rond eerst de huidige timer af!</h1>
        </div>
      </div>

      }
    </form>
  }
  return <form className={"flex items-end space-x-4 w-full"} onSubmit={save}>
    <div className={"w-40"}>
      <InputErrors errors={errors} field={'created_at'}/>
      <Input type={'date'} label={'Datum'} value={date} onChange={setDate}/>
    </div>
    <div className={"w-64"}>
      <InputErrors errors={errors} field={'category'}/>
      <FancyAutocomplete label={'Post'} value={category} onChange={(selection) => setCategory(selection)}
                         options={options}/>
    </div>
    <div className={"w-40"}>
      <InputErrors errors={errors} field={'value'}/>
      <Input type={'time'} label={'Tijd'} value={time} onChange={setTime}/>
    </div>
    <div className={"flex-1"}>
      <InputErrors errors={errors} field={'description'}/>
      <Input type={'text'} label={'Omschrijving'} value={description} onChange={setDescription}/>
    </div>
    <div className={"w-32 flex flex-col items-stretch"}>
      {!loading ? <Button type={'primary'} submit size={'md'} text={"Toevoegen"}/> : <div className={'text-slate-600 dark:text-zinc-400 flex items-center justify-center h-10'}><FontAwesomeIcon spin={true} icon={faCircleNotch} /></div>}
    </div>
  </form>
}