import React, {FC, useEffect} from "react";
import {ContentContainer} from "../components/content/ContentContainer";
import {PageIconHeader} from "../components/layout/PageIconHeader";
import {Breadcrumbs} from "../components/content/Breadcrumbs";
import {PageHeader} from "../components/content/PageHeader";
import {faCopy, faInbox} from "@fortawesome/free-solid-svg-icons";
import {useParams} from "react-router-dom";
import {useFetchedResource} from "../api/APIContext";
import {useApiCall} from "../api/api";
import {useLatestRefreshTime, useRefreshEffect} from "../components/RefreshController";
import {useTenant} from "../tenant/TenantContext";
import {TicketButtons} from "../components/tickets/details/TicketButtons";
import {HelpAssignUser} from "../components/tickets/details/HelpAssignUser";
import {HelpAssignCustomer} from "../components/tickets/details/HelpAssignCustomer";
import {TicketTimelineTab} from "../components/tickets/details/TicketTimelineTab";
import {TabContainer} from "../components/layout/TabContainer";
import {TicketTasksTab} from "../components/tickets/details/TicketTasksTab";
import {TicketTimeTab} from "../components/tickets/details/TicketTimeTab";
import {formatTimeString} from "../util/formatTimeString";
import {PermissionContextProvider, usePermission} from "../permissions/PermissionContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {copyToClipboard} from "../util/copyToClipboard";
import {Ticket} from "../api/dto";
import {TicketSubscribersTab} from "../components/tickets/details/TicketSubscribersTab";
import {useLocalSettings} from "../components/LocalSettingsContext";
import {Button} from "../components/form/Button";
import {faArchive} from "@fortawesome/pro-solid-svg-icons";


export const TicketDetails: FC = () => {
  const {tenant} = useTenant();
  const {ticket} = useParams()
  const {getTicket, getAllTicketsForTenant, getAllPriorities, getAllStatuses, getAllUsersForTenant, getTeams, getCustomers} = useApiCall();
  const {resource: tickets, loading: loadTickets, reload: reloadTickets} = useFetchedResource(() => getAllTicketsForTenant());
  const {resource: ticketDetails, loading: loadTicket, reload: reloadTicket} = useFetchedResource((ticketId: string) => getTicket(ticketId));
  const {resource: ticketStatuses, loading: loadStatuses, reload: reloadStatuses} = useFetchedResource(() => getAllStatuses())
  const {resource: ticketPriorities, loading: loadPriorities, reload: reloadPriorities} = useFetchedResource(() => getAllPriorities())
  const {resource: tenantUsers, loading: loadTenantUsers, reload: reloadTenantUsers} = useFetchedResource(() => getAllUsersForTenant())
  const {resource: teamsResource, loading: loadingTeams, reload: reloadTeams} = useFetchedResource(() => getTeams())
  const {resource: customerResource, loading: loadingCustomers, reload: reloadCustomers} = useFetchedResource(() => getCustomers())
  const latestRefreshTime = useLatestRefreshTime()

  useEffect(() => {
    if (ticket === undefined) {
      console.warn('ticket uuid is undefined')
      return
    }
    reloadTicket(ticket)
  }, [ticket, latestRefreshTime])

  useRefreshEffect(() => {
    reloadTenantUsers(undefined)
    reloadStatuses(undefined)
    reloadPriorities(undefined)
    reloadTeams(undefined)
    reloadCustomers(undefined)
    reloadTickets(undefined)
  })

  const permissions = usePermission()

  const timeSpent = ticketDetails?.times.reduce((total, time) => {
    return total + Number(time.value);
  }, 0) ?? 0
  const customer = customerResource?.find(customer => customer.id === ticketDetails?.customer_id)

  return <ContentContainer size={"2xl"}>
    <PageIconHeader backButton={true} icon={faInbox}>
      <Breadcrumbs crumbs={[
        {label: "Servicedesk", href: `/${tenant}/ticket`},
        {label: "Tickets", href: `/${tenant}/ticket`},
      ]} currentPage={ticketDetails?.subject ?? "Ticket details"} />

      <PageHeader>{ticketDetails?.number && ticketDetails?.subject ? `#${ticketDetails.number} - ${ticketDetails.subject}` : ''}
        {ticketDetails && <span className={"pl-4 dark:text-zinc-700 hover:cursor-pointer hover:dark:text-zinc-400 text-slate-300 hover:text-slate-500 text-base"} onClick={() => copyToClipboard(formatTicketSlug(ticketDetails))}>
          <FontAwesomeIcon icon={faCopy}/>
        </span>}
      </PageHeader>

    </PageIconHeader>

    <div className={'flex flex-row-reverse items-stretch -mt-10'}>
      {ticketDetails && ticketStatuses && ticketPriorities && tenantUsers &&  teamsResource && <>
        <TicketButtons ticket={ticketDetails} status={ticketDetails.status!} priority={ticketDetails.priority!} allStatuses={ticketStatuses} allPriorities={ticketPriorities} customer={customer} tenantUsers={tenantUsers} tenantTeams={teamsResource} otherTickets={tickets ?? []}/>
      </>}
      <div className={'flex-1'}>
        {(ticketDetails && !ticketDetails.customer && customerResource && !customer) ? <HelpAssignCustomer ticket={ticketDetails} customers={customerResource} /> : <></>}
        {(ticketDetails && !ticketDetails.user_id && tenant) ? <HelpAssignUser ticket={ticketDetails} tenant={tenant} /> : <></>}

        {ticketDetails && tenantUsers && <div className={"mt-8"}>
          <TabContainer
            tabs={[
              {name: 'Details', body: <TicketTimelineTab ticket={ticketDetails} otherTickets={tickets ?? []} />},

              ...(permissions.canAccessTicketTasks() ? [
                {name: `Taken (${ticketDetails.tasks.filter(t => t.is_done).length}/${ticketDetails.tasks.length})`, body: <TicketTasksTab ticket={ticketDetails} tenantUsers={tenantUsers} />}
              ] : []),

              ...(permissions.canAccessTicketTimes() ? [
                {name: `Tijd (${formatTimeString(timeSpent)})`, body: <TicketTimeTab ticket={ticketDetails} customer={customer} />},
              ] : []),

              ...((permissions.canAccessSubscribers() && ticketDetails.subscribers.length > 0) ? [
                {name: `Subscribers (${ticketDetails.subscribers.length})`, body: <TicketSubscribersTab ticket={ticketDetails} customer={customer} users={tenantUsers} />},
              ] : []),
            ]}
          />
        </div>}
      </div>
    </div>
  </ContentContainer>
}

function formatTicketSlug(ticket: Ticket): string {
  if (! ticket.subject) {
    return ticket.number
  }
  return `${ticket.number}-${ticket.subject}`.replaceAll(' ', '-').replaceAll(/(\W+)/g, '-').toLowerCase()
}



