import {FC, useCallback, useState} from "react";
import {Input} from "../components/form/Input";
import {ErrorBag, useApiCall, ValidationError} from "../api/api";
import {InputErrors} from "../components/form/InputErrors";
import {ModalFooter} from "../components/layout/ModalProvider";
import {faSave} from "@fortawesome/free-solid-svg-icons";
import {useRefresh} from "../components/RefreshController";
import {Toggle} from "../components/form/Toggle";

export const AddAssetTypeModal: FC<{}> = () => {
  const {addAssetType} = useApiCall()
  const [type, setType] = useState<string>('')
  const [isChecked, setIsChecked] = useState<boolean>(false)
  const [errors, setErrors] = useState<ErrorBag>({})

  const reload = useRefresh()

  const save = useCallback(async () => {
    await addAssetType(type, isChecked).catch(error => {
      if (error instanceof ValidationError) {
        setErrors(error.errors)
        throw new Error('Validation error')
      }
      throw error
    })
    reload()
  }, [type, isChecked])
  return <>
    <form onSubmit={() => save} className={'space-y-4 flex-1'}>
      <div className={''}>
        <Input type={'text'} label={'Type'} value={type} onChange={(t) => setType(t)} placeholder={'E.g. computers'} />
        <InputErrors errors={errors} field={'asset_type_name'} />
      </div>
      <div className={''}>
        <Toggle value={isChecked} onChange={setIsChecked} label={'Fysiek product?'} />
        <InputErrors errors={errors} field={'asset_type_name'} />
      </div>
    </form>
    <ModalFooter text={'Opslaan'} icon={faSave} onSubmit={save} />
  </>
}