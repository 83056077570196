import {Tenant, Ticket, TicketTask, User} from "../../../api/dto";
import React, {FC, useCallback, useEffect, useState} from "react";
import {useRefresh} from "../../RefreshController";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faCircleNotch,
  faPlusCircle,
  faSave,
  faTimes
} from "@fortawesome/free-solid-svg-icons";
import {IconDropdownButton} from "../../form/IconButtonDropdown";
import {faUser} from "@fortawesome/free-regular-svg-icons";
import {Button} from "../../form/Button";
import {useApi} from "../../../api/APIContext";
import {useApiCall} from "../../../api/api";

export const TicketTasksTab: FC<{ticket: Ticket, tenantUsers: User[]}> = ({ticket, tenantUsers}) => {
  const [tasks, setTasks] = React.useState(ticket.tasks)
  const {currentTenant} = useApi()

  useEffect(() => {
    setTasks(ticket.tasks)
  }, [ticket.tasks]);
  const setChecked = async (taskId: string, checked: boolean) => {
    setTasks(tasks.map(t => t.id === taskId ? {...t, done: checked} : t))
    // TODO: Save to API.
  }

  const orderedTasks = ticket.tasks
    .filter(task => task.description.toLowerCase())
    .sort((a, b) => {
      const aChecked = ticket.tasks.some(t => t?.id === a.id)
      const bChecked = ticket.tasks.some(t => t?.id === b.id)
      if (aChecked && !bChecked) return 1
      if (!aChecked && bChecked) return -1
      return a.description.localeCompare(b.description);
    })
  return <div className={'bg-white dark:bg-zinc-700 rounded border border-slate-300 dark:border-zinc-500 px-3 py-2 flex flex-col'}>
    {tasks.length > 0 ?

      //list of tasks
      <>
    {orderedTasks.map((task) => {
      return <TaskEditorRow task={task} ticket={ticket} tenantUsers={tenantUsers} key={task.id} />
    })}
      </>
      //if no list of tasks
      :
      <div className={'mb-2 h-20 flex flex-col items-center justify-center opacity-60 text-sm'}>
        <FontAwesomeIcon icon={faPlusCircle} className={"mb-3"}/>
        <span>Dit ticket heeft geen taken</span>
      </div>}
    {currentTenant && <AddTaskForm ticket={ticket} tenantUsers={tenantUsers} currentTenant={currentTenant} />}
  </div>
}

const TaskEditorRow: FC<{ task:TicketTask, ticket: Ticket, tenantUsers: User[] }> = (props) => {
  const { checkTicketTask, uncheckTicketTask, deleteTicketTask, editTicketTask } = useApiCall()
  const [checked, setChecked] = useState(props.task.is_done)
  const [assignee, setAssignee] = React.useState(props.task.user_uuid);
  const selectedAssignee = props.tenantUsers.find(u => u.id === assignee);
  const refactoredTenantUsers = props.tenantUsers.map((item) => [item.id, item.name]);
  const refresh = useRefresh()
  const reload = useRefresh()
  const toggleTask = async () => {
    setChecked(!checked)
    if (checked) {
      await uncheckTicketTask(props.ticket.id, props.task.id)
    } else {
      await checkTicketTask(props.ticket.id, props.task.id)
    }
    refresh()
  }

  const saveNewValues = useCallback(async (user: string) => {
    const deployment = await editTicketTask(
      props.ticket.id,
      props.task.id,
      user
      )
    reload()
  }, []);

  const deleteTask = async () => {
    await deleteTicketTask(props.ticket.id, props.task.id)
    refresh()
  }
  useEffect(() => {
    setChecked(props.task.is_done)
  }, [props.task]);
  return <div className={"rounded hover:bg-slate-50 dark:hover:bg-zinc-600 group cursor-pointer flex items-stretch min-h-12 py-2 -mx-3 px-3"}>
    <div className={"flex items-center flex-1"} onClick={() => toggleTask()}>
      <div className={`h-6 w-6 rounded-full border border-slate-200 mr-3 flex items-center justify-center ${checked ? 'bg-brand-800 border-brand-800 dark:bg-brand-500 dark:border-brand-500' : 'border-slate-200 dark:border-slate-500'}`}>
        <FontAwesomeIcon icon={faCheck} className={checked ? 'text-white dark:text-zinc-950' : "text-transparent group-hover:text-slate-300 dark:group-hover:text-slate-500"}/>
      </div>
      <div className={`flex-1 pr-4`}>
        <h1>{props.task.description}</h1>
      </div>
    </div>
    <div className={'flex items-center'}>
      {/*<span className={"border border-slate-200 dark:border-zinc-600 dark:bg-zinc-600 rounded px-4 py-1"}>{props.task.user_name}</span>*/}
      <IconDropdownButton type={"secondary"} size={"sm"} text={selectedAssignee?.name ?? '-'} icon={faUser} data={Object.fromEntries(refactoredTenantUsers)} onClick={async (key) => {
        setAssignee(key);
        await saveNewValues(key)
      }}/>
    </div>
    <div className={"flex items-center justify-end w-28"}>
      <div
        onClick={() => deleteTask()}
        className={"h-6 w-6 rounded-full hover:bg-red-200 dark:hover:bg-red-700 ml-1 flex items-center justify-center text-slate-600 dark:text-slate-400 hover:text-red-600 dark:hover:text-red-50"}>
        <FontAwesomeIcon icon={faTimes} className={"h-3"}/>
      </div>
    </div>
  </div>
}

const AddTaskForm: FC<{ ticket: Ticket, tenantUsers: User[], currentTenant: Tenant }> = ({ ticket, tenantUsers, currentTenant }) => {
  const refresh = useRefresh();
  const { addTicketTask } = useApiCall();
  const [text, setText] = React.useState('');
  const [assignee, setAssignee] = React.useState(currentTenant?.userId);
  const refactoredTenantUsers = tenantUsers.map((item) => [item.id, item.name]);
  const selectedAssignee = tenantUsers.find(u => u.id === assignee);
  const [saving, setSaving] = React.useState(false);
  const [rows, setRows] = React.useState(1);

  const submit = async (e: React.FormEvent) => {
    e.preventDefault();
    setSaving(true);

    const tasks = text
      .split(/\r?\n/)
      .map(line => line.trim().replace(/^[-*]\s*/, ''))
      .filter(line => line.length > 0);

    for (const task of tasks) {
      await addTicketTask(ticket.id, assignee, task);
    }
    setText('');
    setRows(1);
    setSaving(false);
    refresh();
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    setText(value);
    const lineCount = value.split(/\r?\n/).length;
    setRows(lineCount);
  };

  return (
    <form onSubmit={submit} className={"flex items-start border-t border-slate-300 -mx-3"}>
      <div className={"flex items-center justify-center h-10 w-10 ml-1 mt-1 -mb-1"}>
        <FontAwesomeIcon icon={saving ? faCircleNotch : faPlusCircle} spin={saving} className={"text-brand-800 dark:text-brand-600"} />
      </div>
      <div className={'flex flex-1 min-h-10 -mb-1 mt-1'}>
        <textarea value={text} onChange={handleTextChange} className={"flex-1 bg-transparent min-h-10 outline-none rounded p-2"} placeholder={'Voer nieuwe taken in...'} rows={rows}/>
      </div>
      <div className={"flex items-start space-x-3 mt-2 mx-2"}>
        <IconDropdownButton type={"secondary"} size={"sm"} text={selectedAssignee?.name ?? '-'} icon={faUser} data={Object.fromEntries(refactoredTenantUsers)} onClick={async (key) => {
            setAssignee(key);
          }}/>
        <Button type={'primary'} disabled={text.trim().length === 0} size={'sm'} text={'Toevoegen'} icon={faSave} submit/>
      </div>
    </form>
  );
};
